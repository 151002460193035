import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';

import { map, catchError, share } from 'rxjs/operators';
 
import { Role } from '../entities/role';
import { SharedService } from './sharedService';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable()
export class AuthService {
  private username$ = new BehaviorSubject<string>(undefined);
  private userRole$ = new BehaviorSubject<string>(undefined);
  accessToken: string
  //constructor(private http: HttpClient, private sharedService: SharedService, private decoder: JwtHelperService) {   }
  constructor(private http: HttpClient, private sharedService: SharedService, private decoder: JwtHelperService) { 
    this.accessToken = sharedService.getAccessToken();
   }
 
  login(username: string, password: string): Observable<boolean> {
    return this.http.post<{ token: string }>(this.sharedService.apiUrlLogin('auth/login'), { username: username, password: password })
      .pipe(
        map(result => {
            localStorage.setItem(this.accessToken, result.token);
            this.setUsername(result['user'].username)
            this.setUserRole$(result['user'].roles)
            return true;
        }), catchError(
          
          (err) => throwError(err)
          
          )
      );
  }

  getToken(){
    return this.decoder.tokenGetter()
  }

  decodeToken(token){
    return this.decoder.decodeToken(token)
  }

  logout() {
    localStorage.removeItem(this.accessToken)
    localStorage.removeItem('username')
    localStorage.removeItem('userrole')
    this.setLoginStatus(false)
  }


  @Output() loggedIn: EventEmitter<boolean> = new EventEmitter();
  setLoginStatus(obj) {
    this.loggedIn.emit(obj)
  }

  setUsername(obj) {
    this.username$.next(obj);
  }

  setUserRole$(obj) {
    this.userRole$.next(obj);
  }

  getUsername$(): Observable<any> {
    return this.username$.asObservable();
  }

  getUserRole$(): Observable<any> {
    return this.userRole$.asObservable();
  }

  // getDecodedToken(){
  //   return this.decoder.decodeToken(this.decoder.tokenGetter())
  // }

 


}
