import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Role } from 'src/app/entities/role';
import { AuthService } from 'src/app/services/auth.service';
import { MenuCategories } from '../top-menu/menuItems';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'atom-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  notifParam: any;
  constructor(public router: Router, public route: ActivatedRoute, private userService: UserService) { 

  }

  ngOnInit() {
    this.userService.getUser().subscribe(user=>{
      var routePath = ''
      if (user.roles){
        if (user.roles.find(elem => elem==='ADMIN')){
          routePath = MenuCategories.get('ADMIN')[0][0]
        }else if (user.roles.find(elem => elem === 'EDITOR')){
          routePath = MenuCategories.get('EDITOR')[0][0]
        }else if (user.roles.find(elem => elem === 'TAXPAYER_DB')){
          routePath = MenuCategories.get('TAXPAYER_DB')[0][0]
        }
        this.router.navigate([routePath]);
      }
    })
 
  }

}
