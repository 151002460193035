import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { District } from 'src/app/entities/district';
import { Division } from 'src/app/entities/division';
import { DivisionService } from 'src/app/services/division.service';

@Component({
  selector: 'atom-district-dialog',
  templateUrl: './district-dialog.component.html',
  styleUrls: ['./district-dialog.component.css']
})
export class DistrictDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  district: any;
  divisions  = []
  selectDivisions: Array<Division>
  constructor(
    public dialogRef: MatDialogRef<DistrictDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public divisionService: DivisionService,
    public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.district = new District()
    if (data){
      this.district = JSON.parse(JSON.stringify(data));
      this.district['class'] = 'DISTRICT'
    }
    this.setEntity(this.district);
    this.setConfirmationDialog(this.confirmationDialog)

    this.divisionService.getList('dictionary/division').subscribe(divisions => {
      this.divisions = divisions.data.data
      this.selectDivisions = this.divisions
    })

  }

  selectChange = (event: any, field) => {
    switch (field) {
      case 'DIVISION':
        this.district.division = event.data
        break;
      default:
        break;
    }
  };

  ngOnInit() { }
}