import { ApplicationState } from './states/applicationState';
import { City } from './city';
import { Division } from './division';

export class Application {
    public id: number;


    //individual only
    public firstname: string;
    public lastname: string;
    public idNumber: string;

    //corporate only
    public name: string;
    public contactPerson: string;

    //both
    public type: string; //COMPANY, INDIVIDUAL
    public address: string;
    public city: City;
    public phoneNumber: string;
    public email: string;
    public incorporationDate: Date;
    public directors: string;
    public founders: string;
    public division: Division;
    public state: ApplicationState;

    constructor() {
 
    }
  
  }  