import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AccessGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (route.data["app"]) {
            if (user.roles) {
              var res = route.data["app"].some((item) =>
                user.roles.includes(item)
              );
              return of(res);
            }
        }    
      })
    )
  }
}
