import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { EntityService } from 'src/app/services/entity.service';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/sharedService';


@Component({
  selector: 'atom-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.css']
})
export class EntityComponent implements OnInit {
  subscriptionUpdateApplication: any;
  displayedColumns: string[] = ['tin', 'legacyTin', 'name', 'type', 'address', 'city', 'division', 'date', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;
  currentFilter: any
  displayedFilters: any[]
  statusValue = 'active';
  defail = false
  reliq = false

  filterObject = {status: this.statusValue}

  constructor(public translateService: TranslateService, private sharedService: SharedService, private entityService: EntityService, public dialog: MatDialog,
    public router: Router) {
    this.sharedService.getLan$().subscribe(lang => {
      this.setFilter()
    })

  }

  handleSelection(event) {
    this.filterObject[this.statusValue] = event.value
    this.dataSource.loadData("taxpayer", '', 'asc', 'name', 0, this.paginator.pageSize, this.filterObject);
  }

  ngOnInit() {
    this.translateService.get('ENTITIES.ALL-ENTITIES').subscribe((translated: string) => {
      this.displayedFilters = new Array<any>(3);
      this.setFilter()
    });
    this.dataSource = new AtomDataSource(this.entityService)
  }


  search(event){
    if (event.source.name === 'reliq'){
      this.reliq = event.checked
    }

    if (event.source.name === 'defail'){
      this.defail = event.checked
    }
    
    if (this.defail){
      this.filterObject['defail']=true
    }else{
      delete this.filterObject['defail']
    }
    if (this.reliq){
      this.filterObject['reliq']=true
    }else{
      delete this.filterObject['reliq']
    }
    this.dataSource.loadData("taxpayer", '', 'asc', 'name', 0, this.paginator.pageSize, this.filterObject);
  }

  setFilter(){
    this.currentFilter = { key: 'active', value: this.translateService.instant('ENTITIES.ACTIVE-ENTITIES') }
    this.displayedFilters[0] = { key: 'active', value: this.translateService.instant('ENTITIES.ACTIVE-ENTITIES') };
    this.displayedFilters[1] = { key: 'disabled', value: this.translateService.instant('ENTITIES.DISABLED-ENTITIES') };
    this.displayedFilters[2] = { key: 'dormant', value: this.translateService.instant('ENTITIES.DORMANT') };
    // this.displayedFilters[3] = { key: 'defailants', value: this.translateService.instant('ENTITIES.ACTIVE-DEFAILANTS') };
    // this.displayedFilters[4] = { key: 'reliquataires', value: this.translateService.instant('ENTITIES.ACTIVE-RELIQUATAIRES') };
    this.displayedFilters[3] = { key: 'all', value: this.translateService.instant('ENTITIES.ALL-ENTITIES') };
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('taxpayer', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => { });

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadEntityTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadEntityTable())
      )
      .subscribe();

  }

  loadEntityTable() {
    this.dataSource.loadData('taxpayer',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      { "status" : this.statusValue });
  }

  openDialog(application): void {

  }

  openEntityView(entity): void {
    this.router.navigate(['/entity/' + entity.id]);
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateApplication) this.subscriptionUpdateApplication.unsubscribe();
  }


}
