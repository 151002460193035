import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ApplicationComponent } from './components/application/application.component';
import { SettingsComponent } from './components/settings/settings.component';
import { EntityComponent } from './components/entity/entity.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { RepresentativeComponent } from './components/representative/representative.component';
import { EntityViewComponent } from './components/entity/entity-view/entity-view.component';
import { ApplicationViewComponent } from './components/application/application-view/application-view.component';
import { AccessGuard } from './guards/access.guard';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: {app:['ADMIN', 'READONLY', 'VIEWER','EDITOR']} },
  { path: 'applications', component: ApplicationComponent, canActivate: [AuthGuard, AccessGuard], data: {app:['ADMIN', 'READONLY', 'VIEWER','EDITOR']}  },
  { path: 'entities', component: EntityComponent, canActivate: [AuthGuard, AccessGuard], data: {app:['ADMIN', 'READONLY', 'VIEWER','EDITOR','TAXPAYER_DB']}  },
  { path: 'representatives', component: RepresentativeComponent, canActivate: [AuthGuard, AccessGuard], data: {app:['ADMIN', 'READONLY', 'VIEWER','EDITOR']}  },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard, AccessGuard], data: {app:['ADMIN', 'READONLY', 'VIEWER']}  },
  { path: 'entity/:id', component: EntityViewComponent, canActivate: [AuthGuard, AccessGuard], data: {app:['ADMIN', 'READONLY', 'VIEWER','EDITOR','TAXPAYER_DB']}  },
  { path: 'application/:id', component: ApplicationViewComponent, canActivate: [AuthGuard, AccessGuard], data: {app:['ADMIN', 'READONLY', 'VIEWER','EDITOR']} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
