import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { SharedService } from 'src/app/services/sharedService';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { RepresentativeDialogComponent } from './dialog/representative-dialog/representative-dialog.component';
import { RepresentativeService } from 'src/app/services/representative.service';

@Component({
  selector: 'atom-representative',
  templateUrl: './representative.component.html',
  styleUrls: ['./representative.component.css']
})
export class RepresentativeComponent implements OnInit {
  subscriptionApproveRepresentative: any;
  displayedColumns: string[] = ['representative', 'name','type','address','city','date','status'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;
  currentFilter = "All Representatives"
  displayedFilters: string[] = ["","",""];

  constructor( public translateService: TranslateService, public dialog: MatDialog, public sharedService: SharedService, 
    private representativeService: RepresentativeService) { 
    this.translateService.get('REPRESENTATIVES.ALL-REPRESENTATIVES').subscribe((translated: string) => {
      this.displayedFilters[0]=this.translateService.instant('REPRESENTATIVES.ALL-REPRESENTATIVES');
      this.displayedFilters[1]=this.translateService.instant('REPRESENTATIVES.TO-APPROVE');
    });

    this.subscriptionApproveRepresentative = this.sharedService.approveRepresentativeEmmitter.subscribe(val => {
          this.loadRepresentativeTable();
    })
  }

  handleSelection(event) {
    var statusFilter="status"
    var statusValue=null
    if (event.value===this.displayedFilters[1])
    statusValue="validation"
    else{
      statusFilter=null;
    }
    this.dataSource.loadData("representative", '', 'asc', 'name', 0, this.paginator.pageSize, {statusFilter: statusValue});
  }

 
  ngOnInit() {
    this.dataSource = new AtomDataSource(this.representativeService)
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('representative', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadRepresentativeTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadRepresentativeTable())
      )
      .subscribe();

  }

  loadRepresentativeTable() {
    this.dataSource.loadData('representative',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      {"status" :'active'});
  }

  openDialog(type, representative): void {
    const dialogRef = this.dialog.open(RepresentativeDialogComponent, {
      width: '850px', panelClass: 'custom-dialog-container',
      data: {type, representative: representative, action: 'APPROVE_REPRESENTATUVE'}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionApproveRepresentative) this.subscriptionApproveRepresentative.unsubscribe();
  }

}
