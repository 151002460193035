import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { ApplicationComponent } from './components/application/application.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MultipleSelectComponent } from './components/custom-ui-elements/multiple-select.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSidenavModule, MatListModule, MatButtonModule,
  MatChipsModule, MatAutocompleteModule,
  MatPaginatorModule, MatCardModule, MatTableModule, MatSortModule, MatDialogModule, MatMenuModule, MatDatepickerModule,
  MatNativeDateModule, MatTabsModule, MatSelectModule, MatOptionModule, MatRadioModule, MatCheckboxModule, MatButtonToggleModule,
  MAT_DIALOG_DATA, MatDialogRef
} from '@angular/material';
import { AuthService } from './services/auth.service';
import { SettingsComponent } from './components/settings/settings.component';
import { EntityComponent } from './components/entity/entity.component';
import { CityComponent } from './components/city/city.component';
import { ActivityDetailsService } from './services/activityDetails.service';
import { ActivitiesService } from './services/activities.service';
import { BranchesService } from './services/branches.service';
import { BaseService } from './services/base.service';
import { SharedService } from './services/sharedService';
import { CityDialogComponent } from './components/city/dialog/city-dialog/city-dialog.component';
import { UserComponent } from './components/user/user.component';
import { DivisionComponent } from './components/division/division.component';
import { DivisionService } from './services/division.service';
import { UserService } from './services/user.service';
import { DivisionDialogComponent } from './components/division/dialog/division-dialog/division-dialog.component';
import { UserDialogComponent } from './components/user/dialog/user-dialog/user-dialog.component';
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { IndividualEntityComponent } from './components/individual-entity/individual-entity.component';
import { IndividualEntityDialogComponent } from './components/individual-entity/dialog/individual-entity-dialog/individual-entity-dialog.component';
import { EntityService } from './services/entity.service';
import { ApplicationService } from './services/application.service';
import { ApplicationDialogComponent } from './components/application/dialog/application-dialog/application-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from './util/auth.interceptor';
import { RepresentativeComponent } from './components/representative/representative.component';
import { RepresentativeDialogComponent } from './components/representative/dialog/representative-dialog/representative-dialog.component';
import { RepresentativeService } from './services/representative.service';
import { EntityDialogComponent } from './components/entity/dialog/entity-dialog/entity-dialog.component';
import { SectorComponent } from './components/sector/sector.component';
import { SectorDialogComponent } from './components/sector/sectors/dialog/sector-dialog/sector-dialog.component';
import { SectorService } from './services/sector.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { CategoryService } from './services/category.service';
import { CategoryDialogComponent } from './components/category/dialog/category-dialog/category-dialog.component';
import { CategoryComponent } from './components/category/category.component';
import { LoggingComponent } from './components/logging/logging.component';
import { TableComponent } from './components/logging/table/table.component';
import { EntityViewComponent } from './components/entity/entity-view/entity-view.component';
import { ApplicationViewComponent } from './components/application/application-view/application-view.component';
import { PostalCodeComponent } from './components/postal-code/postal-code.component';
import { RegionComponent } from './components/region/region.component';
import { RegionDialogComponent } from './components/region/dialog/region-dialog/region-dialog.component';
import { LocationService } from './services/region.service';
import { PostalCodeDialogComponent } from './components/postal-code/dialog/postal-code-dialog/postal-code-dialog.component';
import { PostalCodeService } from './services/postalCode.service';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MultiselectAutocompleteComponent } from './components/multiselect-autocomplete/multiselect-autocomplete.component';
import { DynamicDropdownComponent } from './components/custom-ui-elements/dynamic-dropdown/dynamic-dropdown.component';
import { MunicipalityComponent } from './components/municipality/municipality.component';
import { MunicipalityDialogComponent } from './components/municipality/dialog/municipality-dialog/municipality-dialog.component';
import { DepartmentComponent } from './components/department/department.component';
import { DepartmentDialogComponent } from './components/department/dialog/department-dialog/department-dialog.component';
import { DistrictComponent } from './components/district/district.component';
import { DistrictDialogComponent } from './components/district/dialog/district-dialog/district-dialog.component';
import { SectorsComponent } from './components/sector/sectors/sectors.component';
import { ActivitiesComponent } from './components/sector/activities/activities.component';
import { ActivityDetailsComponent } from './components/sector/activity-details/activity-details.component';
import { ActivityDetailsDialogComponent } from './components/sector/activity-details/dialog/activity-details-dialog/activity-details-dialog.component';
import { ActivitiesDialogComponent } from './components/sector/activities/dialog/activities/activities-dialog.component';
import { BranchesDialogComponent } from './components/sector/branches/dialog/branches/branches-dialog.component';
import { BranchesComponent } from './components/sector/branches/branches.component';
import { AddableComponent } from './components/custom-ui-elements/addable/addable.component';
import { TaxRegimeComponent } from './components/tax-regime/tax-regime.component';
import { TaxRegimeDialogComponent } from './components/tax-regime/dialog/tax-regime-dialog/tax-regime-dialog.component';
import { TaxRegimeService } from './services/taxRegime.service';
import { AccessGuard } from './guards/access.guard';
registerLocaleData(localeFr, 'fr');
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('atom_access_token');
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopMenuComponent,
    ApplicationComponent,
    SettingsComponent,
    EntityComponent,
    CityComponent,
    CityDialogComponent,
    ActivityDetailsDialogComponent,
    ActivitiesDialogComponent, 
    BranchesDialogComponent,
    DistrictComponent,
    DistrictDialogComponent,
    UserComponent,
    DivisionComponent,
    DivisionDialogComponent,
    UserDialogComponent,
    InfoDialogComponent,
    ConfirmationDialogComponent,
    IndividualEntityComponent,
    IndividualEntityDialogComponent,
    ApplicationDialogComponent,
    MultipleSelectComponent,
    LoginComponent,
    RepresentativeComponent,
    RepresentativeDialogComponent,
    EntityDialogComponent,
    SectorComponent,
    SectorDialogComponent,
    CategoryComponent,
    CategoryDialogComponent,
    LoggingComponent,
    TableComponent,
    EntityViewComponent,
    ApplicationViewComponent,
    PostalCodeComponent,
    RegionComponent,
    RegionDialogComponent,
    PostalCodeDialogComponent,
    MultiselectAutocompleteComponent,
    DynamicDropdownComponent,
    MunicipalityComponent,
    MunicipalityDialogComponent,
    DepartmentComponent,
    DepartmentDialogComponent,
    SectorsComponent,
    ActivitiesComponent,
    ActivityDetailsComponent,
    BranchesComponent,
    AddableComponent,
    TaxRegimeComponent,
    TaxRegimeDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule, 
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    FlexLayoutModule,
    MatToolbarModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatSidenavModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    MatOptionModule,
    MatRadioModule,
    MatSortModule,
    MatListModule,
    MatAutocompleteModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    })
  ],
  
  providers: [BaseService, TaxRegimeService, ActivityDetailsService, ActivitiesService, BranchesService, LocationService, RepresentativeService, PostalCodeService, UserService, SharedService, CategoryService, AuthService, DivisionService, SectorService, EntityService, ApplicationService, AuthGuard, AccessGuard, MatDatepickerModule,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ActivityDetailsDialogComponent, ActivitiesDialogComponent, BranchesDialogComponent, TaxRegimeDialogComponent, CityDialogComponent, DivisionDialogComponent, SectorDialogComponent, DistrictDialogComponent, MunicipalityDialogComponent, DepartmentDialogComponent, PostalCodeDialogComponent, RegionDialogComponent, UserDialogComponent, CategoryDialogComponent, EntityDialogComponent, RepresentativeDialogComponent, ConfirmationDialogComponent, InfoDialogComponent, ApplicationDialogComponent]
})
export class AppModule { }
