import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { DivisionService } from 'src/app/services/division.service';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { DivisionDialogComponent } from './dialog/division-dialog/division-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'atom-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css']
})
export class DivisionComponent implements OnInit, OnDestroy {
  subscriptionUpdateDivision: any;
  subscriptionDeleteDivision: any;

  displayedColumns: string[] = ['key', 'name', 'sector', 'postalCode', 'turnoverCompany', 'turnoverIndividual', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private divisionService: DivisionService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateDivision = this.sharedService.updateDivisionEmmitter.subscribe(val => {
      if (val) {
        this.divisionService.updateResource('dictionary/division', val).subscribe(res => {
          this.loadDivisionTable();
        })
      }
    })
  
    this.subscriptionDeleteDivision = this.sharedService.deleteDivisionEmmitter.subscribe(val => {
      if (val) {
        this.divisionService.deleteResource('dictionary/division', val).subscribe(res => {
          this.loadDivisionTable();
        })
      }
    })
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.divisionService)
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/division', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadDivisionTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadDivisionTable())
      )
      .subscribe();

  }

  loadDivisionTable() {
    this.dataSource.loadData('dictionary/division',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openDivisionDialog(division): void {
    const dialogRef = this.dialog.open(DivisionDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: division
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(division): void {
    division['class'] = 'DIVISION';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:division, text: 'DIALOG.DELETE_CURRENT_DIVISION', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateDivision) this.subscriptionUpdateDivision.unsubscribe();
    if (this.subscriptionDeleteDivision) this.subscriptionDeleteDivision.unsubscribe();
  }

}
