import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { LocationService } from 'src/app/services/region.service';
import { RegionDialogComponent } from './dialog/region-dialog/region-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
 

@Component({
  selector: 'atom-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit, OnDestroy {
  subscriptionUpdateRegion: any;
  subscriptionDeleteRegion: any;

  displayedColumns: string[] = ['name', 'tinCode', 'department', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private regionService: LocationService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateRegion = this.sharedService.updateRegionEmmitter.subscribe(val => {
      if (val) {
        this.regionService.updateResource('dictionary/region', val).subscribe(res => {
          this.loadRegionTable();
        })
      }
    })
    this.subscriptionDeleteRegion = this.sharedService.deleteRegionEmmitter.subscribe(val => {
      if (val) {
        this.regionService.deleteResource('dictionary/region', val).subscribe(res => {
          this.loadRegionTable();
        })
      }
    })
 
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.regionService)
    // this.dataSource.loadVisits( '', 'asc', 'name', 0, this.paginator.pageSize);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/region', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadRegionTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadRegionTable())
      )
      .subscribe();

  }

  loadRegionTable() {
    this.dataSource.loadData('dictionary/region',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openRegionDialog(region): void {
    const dialogRef = this.dialog.open(RegionDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: region
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(region): void {
    region['class'] = 'REGION';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:region, text: 'DIALOG.DELETE_CURRENT_REGION', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateRegion) this.subscriptionUpdateRegion.unsubscribe();
    if (this.subscriptionDeleteRegion) this.subscriptionDeleteRegion.unsubscribe();
  }

}
