import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";


export class AtomDataSource implements DataSource<any> {

    private processSubject = new BehaviorSubject<any[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public size = 0;

    constructor(private service: any) {

    }

    loadData(resourceUrl: string,
        filter: any,
        sortDirection: string,
        sortColumn: string,
        pageIndex: number,
        pageSize: number, objectFilter: {}) {
        this.loadingSubject.next(true);
        this.service.getTableData(resourceUrl, filter, sortDirection, sortColumn,
            pageIndex, pageSize,objectFilter).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(result => {
                this.processSubject.next(result['data']['data'])
                this.size = result.data.total
            });
    }

    emptyData() {
        this.processSubject.next(null)
        this.size = 0;
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.processSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.processSubject.complete();
        this.loadingSubject.complete();
    }

}