 
import { Entity } from './entity';
import { City } from './city';
import { Division } from './division';

export class District extends Entity {
    public id: number;
    public name: string;
    public division: Division;

  }  