import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Region } from 'src/app/entities/region';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { Department } from 'src/app/entities/department';
import { LocationService } from 'src/app/services/region.service';

@Component({
  selector: 'atom-region-dialog',
  templateUrl: './region-dialog.component.html',
  styleUrls: ['./region-dialog.component.css']
})
export class RegionDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  region: any;
  departments  = []
  selectDepartments: Array<Department>
 
  constructor(
    public dialogRef: MatDialogRef<RegionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public locationService: LocationService,
    public sharedService: SharedService,public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.region = new Region()
    if (data){
      this.region = JSON.parse(JSON.stringify(data));
      this.region['class'] = 'REGION'
    }
    this.setEntity(this.region);
    this.setConfirmationDialog(this.confirmationDialog)
    this.locationService.getList('dictionary/department').subscribe(departments => {
      this.departments = departments.data.data
      this.selectDepartments = this.departments
    })
  }

  selectChange = (event: any, field) => {
    switch (field) {
      case 'DEPARTMENT':
        this.region.department = event.data
        break;
      default:
        break;
    }
  };

  ngOnInit() { }
}