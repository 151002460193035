import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { ActivityDetail } from 'src/app/entities/activityDetail';
import { DialogCommunicator } from '../../../../dialogs/dialog-communicator';
import { TaxRegime } from 'src/app/entities/taxRegime';
import { TaxRegimeService } from 'src/app/services/taxRegime.service';
import { Division } from 'src/app/entities/division';

@Component({
  selector: 'app-activity-details-dialog',
  templateUrl: './activity-details-dialog.component.html',
  styleUrls: ['./activity-details-dialog.component.css']
})
export class ActivityDetailsDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  activityDetail: any;
  officeRuleStructure: any;

  taxRegimes  = []
  selectTaxRegimes: Array<TaxRegime>

  divisions  = []
  selectDivisions: Array<Division>


  constructor(
    public dialogRef: MatDialogRef<ActivityDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, 
    public taxRegimeService: TaxRegimeService,
    public sharedService: SharedService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.activityDetail = new ActivityDetail()
    if (data) {
      this.activityDetail = JSON.parse(JSON.stringify(data));
      this.activityDetail['class'] = 'ACTIVITYDETAIL'
    } 

    // [{
    //   name: 'city', selectedData: [{ "name": "NGAOUNDAL", "id": "637c729eb29cb613a07fd63f" }],
    //   type: 'multiselect', multiselect: true, key: "name"
    // },
    // {
    //   name: 'division', selectedData: [],
    //   type: 'multiselect', multiselect: false, key: "name"
    // }]

    this.setEntity(this.activityDetail);
    this.setConfirmationDialog(this.confirmationDialog)

    this.taxRegimeService.getList('dictionary/taxRegime').subscribe(taxRegimes => {
      this.taxRegimes = taxRegimes.data.data
      this.selectTaxRegimes = this.taxRegimes
    })
    this.taxRegimeService.getList('dictionary/division').subscribe(divisions => {
      this.divisions = divisions.data.data
      this.selectDivisions = this.divisions
    })
  }
  addData(event){
    this.activityDetail.cityOffice.data.push(event.map(obj=>{return {name:obj.name, selectedData: obj.selectedData}}))
  }
  ngOnInit() { }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.key === value.key;
    return false;
  }
  selectChange = (event: any, field) => {
    switch (field) {
      case 'TAXREGIME':
        this.activityDetail.taxRegime = event.data.length===0?[]:[{ id: event.data[0].id, name: event.data[0].name}]
        break;
        case 'TURNOVER_TAXREGIME':
          this.activityDetail.turnoverTaxRegime = event.data.length===0?[]:[{ id: event.data[0].id, name: event.data[0].name}]
          break;
        case 'DIVISION':
          this.activityDetail.division = event.data.length===0?[]:[{ id: event.data[0].id, name: event.data[0].name}]
          break;
      default:
        break;
    }
  };
}
