import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class DivisionService extends BaseService {

  getSuggestedOffice(id): Observable<any> {
    let $suggestedOffice = this.http.get<any[]>(this.sharedService.apiUrl(`application/${id}/find-office`))
    return $suggestedOffice;
  }

}


