import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { ActivityDetailsDialogComponent } from './dialog/activity-details-dialog/activity-details-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ActivityDetailsService } from 'src/app/services/activityDetails.service';

@Component({
  selector: 'atom-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.css']
})
export class ActivityDetailsComponent implements OnInit, OnDestroy {
  subscriptionUpdateActivityDetail: any;
  subscriptionDeleteActivityDetail: any;
  tabIndex = 0;
  displayedColumns: string[] = ['name', 'taxregime', 'cdi', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private activityDetailsService: ActivityDetailsService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateActivityDetail = this.sharedService.updateActivityDetailEmmitter.subscribe(val => {
      if (val) {
        this.activityDetailsService.updateResource('dictionary/activityDetail', val).subscribe(res => {
          this.loacActivityDetailsTable();
        })
      }
    })
  
    this.subscriptionDeleteActivityDetail = this.sharedService.deleteActivityDetailEmmitter.subscribe(val => {
      if (val) {
        this.activityDetailsService.deleteResource('dictionary/activityDetail', val).subscribe(res => {
          this.loacActivityDetailsTable();
        })
      }
    })
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.activityDetailsService)
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/activityDetail', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loacActivityDetailsTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loacActivityDetailsTable())
      )
      .subscribe();

  }

  loacActivityDetailsTable() {
    this.dataSource.loadData('dictionary/activityDetail',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openActivityDetailDialog(activityDetail): void {
    const dialogRef = this.dialog.open(ActivityDetailsDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: activityDetail
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(activityDetail): void {
    activityDetail['class'] = 'ACTIVITYDETAIL';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:activityDetail, text: !activityDetail.profession?'DIALOG.DELETE_CURRENT_ACTIVITY_DETAIL':'DIALOG.DELETE_CURRENT_PROFESSION', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateActivityDetail) this.subscriptionUpdateActivityDetail.unsubscribe();
    if (this.subscriptionDeleteActivityDetail) this.subscriptionDeleteActivityDetail.unsubscribe();
  }

}
