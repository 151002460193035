import { City } from './city';
import { Entity } from './entity';
import { PostalCode } from './postalCode';

export class Division extends Entity {
    public id: number;
    public key: string;
    public name: string;
    public cities: City[];
    public postalCodes: PostalCode[];
    public sectors: any[];
    public parentDivision: any;
  }  