import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { DistrictDialogComponent } from './dialog/district-dialog/district-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { LocationService } from 'src/app/services/region.service';
 

@Component({
  selector: 'atom-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.css']
})
export class DistrictComponent implements OnInit, OnDestroy {
  subscriptionUpdateDistrict: any;
  subscriptionDeleteDistrict: any;

  displayedColumns: string[] = ['name', 'division', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private locationService: LocationService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateDistrict = this.sharedService.updateDistrictEmmitter.subscribe(val => {
      if (val) {
        this.locationService.updateResource('dictionary/district', val).subscribe(res => {
          this.loadDistrictTable();
        })
      }
    })
    this.subscriptionDeleteDistrict = this.sharedService.deleteDistrictEmmitter.subscribe(val => {
      if (val) {
        this.locationService.deleteResource('dictionary/district', val).subscribe(res => {
          this.loadDistrictTable();
        })
      }
    })
 
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.locationService)
    // this.dataSource.loadVisits( '', 'asc', 'name', 0, this.paginator.pageSize);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/district', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadDistrictTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadDistrictTable())
      )
      .subscribe();

  }

  loadDistrictTable() {
    this.dataSource.loadData('dictionary/district',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openDistrictDialog(district): void {
    const dialogRef = this.dialog.open(DistrictDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: district
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(district): void {
    district['class'] = 'DISTRICT';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:district, text: 'DIALOG.DELETE_CURRENT_DISTRICT', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateDistrict) this.subscriptionUpdateDistrict.unsubscribe();
    if (this.subscriptionDeleteDistrict) this.subscriptionDeleteDistrict.unsubscribe();
  }

}
