import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { DepartmentDialogComponent } from './dialog/department-dialog/department-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { LocationService } from 'src/app/services/region.service';
 

@Component({
  selector: 'atom-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit, OnDestroy {
  subscriptionUpdateDepartment: any;
  subscriptionDeleteDepartment: any;

  displayedColumns: string[] = ['name', 'tinCode', 'municipality', 'city', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private locationService: LocationService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateDepartment = this.sharedService.updateDepartmentEmmitter.subscribe(val => {
      if (val) {
        this.locationService.updateResource('dictionary/department', val).subscribe(res => {
          this.loadDepartmentTable();
        })
      }
    })
    this.subscriptionDeleteDepartment = this.sharedService.deleteDepartmentEmmitter.subscribe(val => {
      if (val) {
        this.locationService.deleteResource('dictionary/department', val).subscribe(res => {
          this.loadDepartmentTable();
        })
      }
    })
 
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.locationService)
    // this.dataSource.loadVisits( '', 'asc', 'name', 0, this.paginator.pageSize);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/department', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadDepartmentTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadDepartmentTable())
      )
      .subscribe();

  }

  loadDepartmentTable() {
    this.dataSource.loadData('dictionary/department',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openDepartmentDialog(department): void {
    const dialogRef = this.dialog.open(DepartmentDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: department
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(department): void {
    department['class'] = 'DEPARTMENT';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:department, text: 'DIALOG.DELETE_CURRENT_DEPARTMENT', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateDepartment) this.subscriptionUpdateDepartment.unsubscribe();
    if (this.subscriptionDeleteDepartment) this.subscriptionDeleteDepartment.unsubscribe();
  }

}
