import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DivisionService } from 'src/app/services/division.service';
import { EntityService } from 'src/app/services/entity.service';
import { LocationService } from 'src/app/services/region.service';
import { SectorService } from 'src/app/services/sector.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-entity-view',
  templateUrl: './entity-view.component.html',
  styleUrls: ['./entity-view.component.css']
})
export class EntityViewComponent implements OnInit {
  entity: any;
  cities = []
  divisions = []
  postalCodes = []
  sectors = []
  additionalFields = []
  regions = []
  departments = []
  municipalities = []
  districts = []
  branches = []
  activities = []
  activityDetails = []

  constructor(private locationService: LocationService, private divisionService: DivisionService, public dialog: MatDialog, private sectorService: SectorService,
    private entityService: EntityService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.loadEntity()
  }

  loadEntity() {
    this.locationService.getList('dictionary/region').subscribe(regions => {
      this.locationService.getList('dictionary/department').subscribe(departments => {
        this.locationService.getList('dictionary/city').subscribe(cities => {
          this.locationService.getList('dictionary/municipality').subscribe(municipalities => {
            this.locationService.getList('dictionary/district').subscribe(districts => {
              this.divisionService.getList('dictionary/division').subscribe(divisions => {
                this.sectorService.getList('dictionary/sector').subscribe(sectors => {
                  this.sectorService.getList('dictionary/postalcode').subscribe(postalCodes => {
                    this.sectorService.getList('dictionary/branch').subscribe(branches => {
                      this.sectorService.getList('dictionary/activity').subscribe(activities => {
                        this.sectorService.getList('dictionary/activityDetail').subscribe(activityDetails => {
                          this.regions = regions.data.data
                          this.departments = departments.data.data
                          this.cities = cities.data.data
                          this.municipalities = municipalities.data.data
                          this.districts = districts.data.data
                          this.divisions = divisions.data.data
                          this.sectors = sectors.data.data
                          this.branches = branches.data.data
                          this.activities = activities.data.data
                          this.activityDetails = activityDetails.data.data
                          this.postalCodes = postalCodes.data.data
                          this.entityService.getResource('taxpayer', this.route.snapshot.paramMap.get('id')).subscribe(taxp => {
                            this.entity = taxp.data
                            for (var key in this.entity.additionalFields) {
                              this.entity.type.additionalFields.forEach(elem => {
                                if (elem.key === key) {
                                  elem.value = this.entity.additionalFields[key]
                                  this.additionalFields.push(elem)
                                }
                              })
                            }
                          })
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  }

  openConfirmationDialog(application, action): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: { obj: application, text: 'DIALOG.ENTITY_' + action, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.entityService.modifyEntityResource('taxpayer', { id: this.entity.id, division: this.entity.division }).subscribe(res => {
        // this.snackBar.open(this.translateService.instant('APPLICATIONS.' + action));
        //  this.entity.active = true;
      })
    });
  }

  getName(name) {
    return name[localStorage.getItem('default_lan')]
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.id === value.id;
    return false;
  }

  isReadonly() {
    return this.entity.id && ['registration', 'validation'].includes(this.entity.status)
  }

  onCancelClick() {
    this.router.navigate(['entities']);
  }

  deactivate() {
    this.entityService.modifyResource('taxpayer', this.entity.id, 'deactivate').subscribe(res => {
      this.loadEntity()
    })
  }
  activate() {
    this.entityService.modifyResource('taxpayer', this.entity.id, 'activate').subscribe(res => {
      this.loadEntity()
    })
  }

}
