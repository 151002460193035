import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Activity } from 'src/app/entities/activity';
import { DialogCommunicator } from '../../../../dialogs/dialog-communicator';
import { ActivityDetail } from 'src/app/entities/activityDetail';
import { ActivityDetailsService } from 'src/app/services/activityDetails.service';

@Component({
  selector: 'app-activities-dialog',
  templateUrl: './activities-dialog.component.html',
  styleUrls: ['./activities-dialog.component.css']
})
export class ActivitiesDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  activity: any;
  activityDetails  = []
  selectActivityDetails: Array<ActivityDetail>
 
  constructor(
    public dialogRef: MatDialogRef<ActivitiesDialogComponent>, public activityDetailService: ActivityDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.activity = new Activity()
    if (data){
      this.activity = JSON.parse(JSON.stringify(data));
      this.activity['class'] = 'ACTIVITY'
    }
    this.setEntity(this.activity);
    this.setConfirmationDialog(this.confirmationDialog)
    this.activityDetailService.getList('dictionary/activityDetail').subscribe(activityDetails => {
      this.activityDetails = activityDetails.data.data
      this.selectActivityDetails = this.activityDetails
    })
  }
  selectChange = (event: any, field) => {
    switch (field) {
      case 'ACTIVITYDETAIL':
        this.activity.activityDetail = event.data
        break;
      default:
        break;
    }
  };
  ngOnInit() { }
}
