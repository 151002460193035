import { Component, OnInit, Inject } from '@angular/core';
import { EventEmitter } from 'events';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/entities/dialogData';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  text: string;
  emitter: EventEmitter;
  obj: any;
  action: any;
  processes = []

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService) {
    this.text = data['text'];
    this.obj = data['obj']
    this.action = data['action']
  }

  ngOnInit() {

  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick(): void {
    if(['DELETE'].includes(this.action)){
      this.sharedService.deleteEntity(this.obj)
      this.dialogRef.close(true);
    }
    else if(['APPROVE_REPRESENTATUVE'].includes(this.action)){
      this.sharedService.deleteEntity(this.obj)
      this.dialogRef.close(true);
    }
    else if (['DEACTIVATE','ACTIVATE'].includes(this.action)){
      this.dialogRef.close(true);
    }
    else {
      this.sharedService.updateEntity(this.obj)
      this.dialogRef.close(true);
    }
   

  }

}