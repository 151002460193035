import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { saveAs } from 'file-saver';
import { DivisionService } from 'src/app/services/division.service';
import { CategoryService } from 'src/app/services/category.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatGridTileHeaderCssMatStyler, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { EntityService } from 'src/app/services/entity.service';
import { LocationService } from 'src/app/services/region.service';
@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.css']
})
export class ApplicationViewComponent implements OnInit {
  application: any;
  cities = []
  divisions = []
  backRoute: any
  tab: any
  durationInSeconds = 3;
  companyTypes: any[]
  entity: any
  additionalFields = []
  constructor(private applicationService: ApplicationService, public dialog: MatDialog, private route: ActivatedRoute,
    public translateService: TranslateService,
    private categoryService: CategoryService, private snackBar: MatSnackBar,
    private locationService: LocationService, private divisionService: DivisionService,
    private entityService: EntityService,
    public router: Router) { }

  ngOnInit() {
    this.backRoute = this.route.snapshot.queryParamMap.get('backRoute')
    this.tab = this.route.snapshot.queryParamMap.get('tab')
    var lan = localStorage.getItem('default_lan')
    this.applicationService.getResource('application', this.route.snapshot.paramMap.get('id')).subscribe(application => {
      this.application = application.data

      this.applicationService.getResource('dictionary/category', this.application.type.id).subscribe(currentCategory => {


        for (var key in this.application.additionalFields) {
          var field = this.application.additionalFields[key]
          var strField = currentCategory.data.additionalFields.find(elem => elem.key === key)
          if (['text', 'number'].includes(strField.type)) {
            this.additionalFields.push({ name: strField.name[lan], value: field })
          }
        }
        this.entityService.getResource('taxpayer', this.application.taxpayerId).subscribe(
          entity => {
            this.entity = entity.data
          }
        )
        this.categoryService.getList('dictionary/category').subscribe(categories => {
          this.locationService.getList('dictionary/city').subscribe(cities => {
            this.divisionService.getList('dictionary/division').subscribe(divisions => {
              this.cities = cities.data.data
              this.divisions = divisions.data.data
              this.companyTypes = categories.data.data
            })
          })
        })
        this.divisionService.getSuggestedOffice(this.application.id).subscribe(office => {
          if (office.data && !this.application.division) {
            this.application.division = { id: office.data.id, name: office.data.name }
          }
        })
      })
    })
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.id === value.id;
    return false;
  }

  openConfirmationDialog(application, action): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: { obj: application, text: 'DIALOG.APPLICATION_' + action, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && action !== 'REJECT') {
        this.entityService.modifyResource('taxpayer', this.application.taxpayerId, 'activate').subscribe(res => {
          this.snackBar.open(this.translateService.instant('APPLICATIONS.' + action));
          this.entity.active = true;
        })
      }
    });
  }


  isReadonly() {
    return this.application.id && ['registration', 'validation'].includes(this.application.status)
  }

  goBack() {
    var qp = { queryParams: {} }
    if (this.tab) {
      qp.queryParams['tab'] = this.tab
    }
    if (JSON.stringify(qp.queryParams) === '{}')
      this.router.navigate(['/' + this.backRoute]);
    else
      this.router.navigate(['/' + this.backRoute], qp);
  }

  downloadFile(key) {
    this.applicationService.downloadFile(key).subscribe
      (data => {
        const blob = data;
        const file = new Blob([blob], {});
        const filename = key;
        saveAs(file, filename);
      });
  }

}
