import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base.service';
 

@Injectable({
  providedIn: 'root'
})
export class RepresentativeService extends BaseService{

  changeRepresentativeStatus(resourceUrl: string, resource: any): Observable<any> {
    return this.http.put<any>(this.sharedService.apiUrl(resourceUrl), resource, super.httpOptions).pipe(
      tap((resource: any) => this.log(`statusChange ${resourceUrl} w/ id=${resource.id}`)),
      catchError(this.handleError<any>('statusChange'))
    );
}
}

 
