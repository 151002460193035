import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { PostalCode } from 'src/app/entities/postalCode';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { LocationService } from 'src/app/services/region.service';

@Component({
  selector: 'atom-postal-code-dialog',
  templateUrl: './postal-code-dialog.component.html',
  styleUrls: ['./postal-code-dialog.component.css']
})
export class PostalCodeDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  postalCode: any;
  regions  = []
  constructor(
    public dialogRef: MatDialogRef<PostalCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public regionService: LocationService,
    public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.postalCode = new PostalCode()
    if (data){
      this.postalCode = JSON.parse(JSON.stringify(data));
      this.postalCode['class'] = 'POSTALCODE'
    }
    this.setEntity(this.postalCode);
    this.setConfirmationDialog(this.confirmationDialog)

    this.regionService.getList('dictionary/region').subscribe(regions => {
      this.regions = regions.data.data
      if (this.regions && this.postalCode.region){
        var existingSector = this.regions.find(elem => elem.id === this.postalCode.region.id)
        this.regions.splice(this.regions.indexOf(existingSector), 1, this.postalCode.region)
      }
    })

  }

  ngOnInit() { }
}