/**
 * Created by aleco on 9/26/2017.
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: '<atom-multiple-select>',
  templateUrl: './multiple-select.component.html'
})
export class MultipleSelectComponent implements OnInit, OnChanges {
  @ViewChild('multiSelect', { static: false }) public multiSelect: NgSelectComponent;
  @Input()
  items: any[];
  @Input()
  activeItems: any[];
  @Input()
  key: any[];
  @Input()
  display: any[];
  @Input()
  placeholder: any;

  @Input()
  name: any;

  private value
  private _disabledV: string = '0';
  private disabled: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      // only run when property "data" changed
      if (propName === 'items') {
        //  this line will update posts values 
        this.items = changes[propName].currentValue

      }
    }
  }
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  filter(event) {
    this.onUpdate.emit(event)
  }
  ngOnInit() {
  }

  private get disabledV(): string {
    return this._disabledV;
  }

  private set disabledV(value: string) {
    this._disabledV = value;
    this.disabled = this._disabledV === '1';
  }

  public selected(value: any): void {
  }

  public removed(value: any): void {
  }

  public refreshValue(value: any): void {
    this.value = value;
  }

  public resetMultiSelectItems() {
    if (this.multiSelect) {
      this.multiSelect.handleClearClick();
    }
  }
}
