import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit {
  tab: any;
  tabIndex = 0;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {

    this.tab = this.route.snapshot.queryParamMap.get('tab')
    switch (this.tab) {
      case 'logs':
        this.tabIndex = 6;
        break;
    
      default:
        this.tabIndex = 0;
        break;
    }
 
  }

}
