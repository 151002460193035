import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './sharedService';
import { catchError, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class BaseService {
   public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(public http: HttpClient, public sharedService: SharedService, public router: Router) { }

  getList(resourceUrl:string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl)).pipe(
      map(res => {
        return res;
      })
    );
  }


  public getTableData(resourceUrl:string,
    filter = '', sortOrder = 'asc', sortColumn = '',
    pageNumber = 0, pageSize = 10, objectFilter): Observable<any> {

    var objFilter = ''
    for (var key in objectFilter){
      objFilter += "&"+key+'='+objectFilter[key]
    }

    return this.http.get(this.sharedService.apiUrl(resourceUrl)+'?q=' + filter +
    '&from=' + pageNumber +
    '&size=' + pageSize +
    objFilter + 
    '&sort=' +sortColumn +':' +
    (sortOrder==='desc'?'-1':'1')).pipe(
      map(res => {
        return res;
      })
    );
  }

  getNumberOfRecords(resourceUrl:string, query: string ,customField, customFieldValue): Promise<number> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl) +
        '/get-number-of-records?q=' + query +
        (customField!==null?'&'+customField+"="+customFieldValue:''))
      .toPromise()
      .then(response => {
        return response['data'].count
      })
      .catch(error => this.handleError(error));
  }

  getResource(resourceUrl:string, id:any): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl+'/'+id)).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateResource(resourceUrl: string, resource: any): Observable<any> {

    if (resource.id) {
      return this.http.put<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), resource, this.httpOptions).pipe(
        tap((resource: any) => this.log(`updated ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('update'))
      );
    }
    else {
      return this.http.post<any>(this.sharedService.apiUrl(resourceUrl), resource, this.httpOptions).pipe(
        tap((resource: any) => this.log(`added ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('add'))
      );
    }
  }

  modifyEntityResource(resourceUrl: string, resource: any): Observable<any> {
      return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id)+'modify', resource, this.httpOptions).pipe(
        tap((resource: any) => this.log(`updated ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('update'))
      );
  }

  blacklistResource(resourceUrl: string, resource: any): Observable<any> {
    if (resource.id) {
      if (resource.status==='ACTIVE')
      return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), {}, this.httpOptions).pipe(
        tap((resource: any) => this.log(`updated ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('update'))
      );
      if (resource.status==='BLACK_LISTED')
      return this.http.put<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), {blackListReason:resource.blackListReason}, this.httpOptions).pipe(
        tap((resource: any) => this.log(`updated ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('update'))
      );
    }
    else {
      return this.http.post<any>(this.sharedService.apiUrl(resourceUrl), resource, this.httpOptions).pipe(
        tap((resource: any) => this.log(`added ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('add'))
      );
    }
  }

  deactivateResource(resourceUrl: string, resource: any): Observable<any> {
    return this.http.delete<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id)).pipe(
      tap((resource: any) => this.log(`deactivated ${resourceUrl} w/ id=${resource.id}`)),
      catchError(this.handleError<any>('deactivate'))
    );
  }

  activateResource(resourceUrl: string, resource: any): Observable<any> {
    return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), null, this.httpOptions).pipe(
      tap((resource: any) => this.log(`activated ${resourceUrl} w/ id=${resource.id}`)),
      catchError(this.handleError<any>('activate'))
    );
  }

  modifyResource(resourceUrl: string, id: any, action: any): Observable<any> {
    return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + id)+action, null, this.httpOptions).pipe(
      tap((resource: any) => this.log(`${action} ${resourceUrl} w/ id=${resource.id}`)),
      catchError(this.handleError<any>(action))
    );
  }

  deleteResource(resourceUrl: string, resource: any): Observable<any> {
    return this.http.delete<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id)).pipe(
      tap((resource: any) => this.log(`deleted ${resourceUrl} w/ id=${resource.id}`)),
      catchError(this.handleError<any>('delete'))
    );
  }

  changeStatus(resourceUrl: string, resource: any): Observable<any> {
    return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource), null, this.httpOptions).pipe(
      tap((resource: any) => this.log(`status change ${resourceUrl} w/ id=${resource}`)),
      catchError(this.handleError<any>('status change'))
    );
  }

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}


