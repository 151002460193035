import { Component, OnInit } from "@angular/core";
import { AuthService } from "./services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SharedService } from "./services/sharedService";
import { filter, map } from "rxjs/operators";
import { UserService } from "./services/user.service";
//import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  year = new Date().getFullYear();
  isLoggedIn = false;
  title = "RDF Atom";
  lang: string;
  constructor(
    public translate: TranslateService,
    public sharedService: SharedService,
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    translate.setDefaultLang("fr");
    this.setLang(
      localStorage.getItem("default_lan")
        ? localStorage.getItem("default_lan")
        : "fr"
    );
    authService.loggedIn.subscribe((val) => {
      this.isLoggedIn = val;
    });
  }

  ngOnInit() {

      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === "primary")
        )
        .subscribe((event) => {
          event.data.subscribe((routeData) => {
            if (routeData.app !== undefined) {
              this.userService.getUser().subscribe((user) => {
                if (user.roles){
                  var res = routeData.app.some((item) => user.roles.includes(item));
                  
                }
              })
           
            }
            // this.auth.isUserActive.subscribe((valUser) => {
            //   this.activeUser = valUser;
            //   if (!this.activeUser && this.isLoggedIn) {
            //     this.userService.getCurrentUser().subscribe((user) => {
            //       if (user !== null) {
            //         this.username = user.username;
            //       }
            //     });
            //   }
            // });
            this.authService.loggedIn.subscribe((val) => {
              this.isLoggedIn = val;
            });
          });
    });
  }

  setLang(lang) {
    //this.sharedService.setLan(lang)
    this.lang = lang;
    this.translate.use(lang);
  }
}
