import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Role } from 'src/app/entities/role';
import { MenuCategories } from '../top-menu/menuItems';
import { RoleName } from 'src/app/entities/util';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'atom-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public username: string;
  public password: string;
  public error: string;
  roleNames = RoleName
  constructor(public authService: AuthService, public router: Router, private userService: UserService) { }

  ngOnInit() {
      
   
  }

  login(username, password) {
    this.authService.login(username.value, password.value).subscribe(result => {
      this.userService.getUser().subscribe(user=>{
        var routePath = ''
        if (user.roles){
          if (user.roles.find(elem => elem==='ADMIN')){
            routePath = MenuCategories.get('ADMIN')[0][0]
          }else if (user.roles.find(elem => elem === 'EDITOR')){
            routePath = MenuCategories.get('EDITOR')[0][0]
          }else if (user.roles.find(elem => elem === 'TAXPAYER_DB')){
            routePath = MenuCategories.get('TAXPAYER_DB')[0][0]
          }
          this.router.navigate([routePath]);
        }
        
      })
    },
      err => {
        if (err.status === 0) {
          this.error = 'ERRORS.CONNECTION_FAILED'
        }
        if (err.status === 401) {
          this.error = 'ERRORS.UNAUTHORIZED';
        }
      })
  }

}

