import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { TaxRegimeService } from 'src/app/services/taxRegime.service';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { TaxRegimeDialogComponent } from './dialog/tax-regime-dialog/tax-regime-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'atom-tax-regime',
  templateUrl: './tax-regime.component.html',
  styleUrls: ['./tax-regime.component.css']
})
export class TaxRegimeComponent implements OnInit, OnDestroy {
  subscriptionUpdateTaxRegime: any;
  subscriptionDeleteTaxRegime: any;
  tabIndex = 0;
  displayedColumns: string[] = ['name', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private taxRegimeService: TaxRegimeService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateTaxRegime = this.sharedService.updateTaxRegimeEmmitter.subscribe(val => {
      if (val) {
        this.taxRegimeService.updateResource('dictionary/taxRegime', val).subscribe(res => {
          this.loadTaxRegimeTable();
        })
      }
    })
  
    this.subscriptionDeleteTaxRegime = this.sharedService.deleteTaxRegimeEmmitter.subscribe(val => {
      if (val) {
        this.taxRegimeService.deleteResource('dictionary/taxRegime', val).subscribe(res => {
          this.loadTaxRegimeTable();
        })
      }
    })
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.taxRegimeService)
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/taxRegime', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadTaxRegimeTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTaxRegimeTable())
      )
      .subscribe();

  }

  loadTaxRegimeTable() {
    this.dataSource.loadData('dictionary/taxRegime',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openTaxRegimeDialog(taxRegime): void {
    const dialogRef = this.dialog.open(TaxRegimeDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: taxRegime
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(taxRegime): void {
    taxRegime['class'] = 'TAXREGIME';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:taxRegime, text: 'DIALOG.DELETE_CURRENT_TAX_REGIME', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateTaxRegime) this.subscriptionUpdateTaxRegime.unsubscribe();
    if (this.subscriptionDeleteTaxRegime) this.subscriptionDeleteTaxRegime.unsubscribe();
  }

}
