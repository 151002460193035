import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './sharedService';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService{

  getUser(): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('user/user/sub')).pipe(
      map(res => {
        return res;
      })
    );
  }

}



