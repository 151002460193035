import { UserStatus } from './states/userStatus';
import { Division } from './division';

export class User {
  public id: number;
  public username: string;
  public status: UserStatus;
  public roles : [];
  public division: Division;
  constructor() {
 
  }

}  