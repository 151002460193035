import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { City } from 'src/app/entities/city';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { Municipality } from 'src/app/entities/municipality';
import { District } from 'src/app/entities/district';
import { MultiselectAutocompleteComponent } from 'src/app/components/multiselect-autocomplete/multiselect-autocomplete.component';
import { LocationService } from 'src/app/services/region.service';

@Component({
  selector: 'atom-city-dialog',
  templateUrl: './city-dialog.component.html',
  styleUrls: ['./city-dialog.component.css']
})
export class CityDialogComponent extends DialogCommunicator implements OnInit {
  @ViewChild('municipalitySelect', { static: false }) munSel: MultiselectAutocompleteComponent;
  @ViewChild('districtSelect', { static: false }) disSel: MultiselectAutocompleteComponent;
  text: string;
  emitter: EventEmitter;
  city: any;

  municipalities = []
  selectMunicipalities: Array<Municipality>
  districts = []
  selectDistricts: Array<District>

  constructor(
    public dialogRef: MatDialogRef<CityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public locationService: LocationService,
    public sharedService: SharedService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.city = new City()
    if (data) {
      this.city = JSON.parse(JSON.stringify(data));
      this.city['class'] = 'CITY'
    }
    this.setEntity(this.city);
    this.setConfirmationDialog(this.confirmationDialog)

    this.locationService.getList('dictionary/municipality').subscribe(municipalities => {
      this.municipalities = municipalities.data.data
      this.selectMunicipalities = this.municipalities
    })
    this.locationService.getList('dictionary/district').subscribe(districts => {
      this.districts = districts.data.data
      this.selectDistricts = this.districts
    })
  }

  selectChange = (event: any, field) => {
    switch (field) {
      case 'MUNICIPALITY':
        this.city.municipality = event.data
        this.city.district = null
        this.disSel.resetAll()
        break;
      case 'DISTRICT':
        this.city.district = event.data
        this.city.municipality = null
        this.munSel.resetAll()
        break;
      default:
        break;
    }
  };

  ngOnInit() { }
}