import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Application } from 'src/app/entities/application';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { DivisionService } from 'src/app/services/division.service';
import { ApplicationService } from 'src/app/services/application.service';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { CategoryService } from 'src/app/services/category.service';
import { LocationService } from 'src/app/services/region.service';
@Component({
  selector: 'atom-application-dialog',
  templateUrl: './application-dialog.component.html',
  styleUrls: ['./application-dialog.component.css']
})
export class ApplicationDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  application: any;
  corporate: boolean;
  cities = []
  regions = []
  divisions = []
  municipalities = []
  companyTypes = []
  departments = []
  typeContribuables = [{ name: 'Personne Physique' }, { name: 'Société' }]
  sex = [{ name: 'M' }, { name: 'F' }]
  etatCivil = [{ name: 'etatCivil1' }, { name: 'etatCivil2' }]
  paysNationalite = [{ name: 'Cameroun' }, { name: 'Ghana' }]
  paysnaissance = [{ name: 'Cameroun' }, { name: 'Ghana' }]
  activity = [{ name: 'act1' }, { name: 'act2' }]
  sectordgi = [{ name: 'sec1' }, { name: 'sec2' }]
  classINS = [{ name: 'cls1' }, { name: 'cls2' }]
  regimeFiscal = [{ name: 'reg1' }, { name: 'reg2' }]
  unitegestion = [{ name: 'un1' }, { name: 'un2' }]
  ville = [{ name: 'vil1' }, { name: 'vil2' }]
  etat = [{ name: 'etat1' }, { name: 'etat2' }]
  online = [{ name: 'onlin1' }, { name: 'onlin2' }]

  constructor(
    public dialogRef: MatDialogRef<ApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public applicationService: ApplicationService,
    public categoryService: CategoryService,
    public locationService: LocationService,
    public divisionService: DivisionService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.application = new Application()

    this.locationService.getList('dictionary/region').subscribe(regions => {
      this.locationService.getList('dictionary/department').subscribe(departments => {
      this.locationService.getList('dictionary/city').subscribe(cities => {
        this.categoryService.getList('dictionary/category').subscribe(categories => {
          this.divisionService.getList('dictionary/division').subscribe(divisions => {
            this.regions = regions.data.data
            // this.departments = departments.data.data
            // this.cities = cities.data.data
            this.divisions = divisions.data
            if (data) {
              var appData = JSON.parse(JSON.stringify(data));
              if (appData['application']) {
                this.application = appData['application'];
                this.application['class'] = 'APPLICATION'
              } else {
                this.application.type = appData.type
              }
            }
            this.companyTypes = categories.data.data.filter(elem => elem.taxPayerType === this.application.type)
            this.setEntity(this.application);
            this.setConfirmationDialog(this.confirmationDialog)
            this.divisionService.getSuggestedOffice(this.application.id).subscribe(office => {
              if (office.data && !this.application.division) {
                this.application.division = { id: office.data.id, name: office.data.name }
              }
            })
          })
        })
      })
      })
    })

  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.id === value.id;
    return false;
  }

  approve() {
    var action = this.application.status === 'registration' ? 'validate' : 'approve'
    this.applicationService.changeApplicationStatus('application' + '/' + this.application.id + '/' + action, { comment: this.application.comment, division: this.application.division }).subscribe(result => {
      this.dialogRef.close()

    })
  }
  updateDepartmentsSelect(region){
    this.applicationService.getLocations('dictionary/region/'+region.value.id+'/department').subscribe(departments => {
      this.departments = departments.data.data
      this.cities = []
      this.municipalities = []
    })
    
  }
  updateCitiesSelect(department){
    this.applicationService.getLocations('dictionary/department/'+department.value.id+'/city').subscribe(cities => {
      this.cities = cities.data.data
      this.municipalities = []
    })
  }
  updateMunicipalitiesSelect(city){
    this.applicationService.getLocations('dictionary/city/'+city.value.id+'/municipality').subscribe(municipalities => {
      this.municipalities = municipalities.data.data
    })
  }

  isReadonly() {
    return this.application.id && ['registration', 'validation'].includes(this.application.status)
  }

  ngOnInit() { }

  downloadFile(key) {
    //this.spinner.show();
    this.applicationService.downloadFile(key).subscribe
      (data => {
        //  this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = key;
        saveAs(file, filename);
      });
  }
  resetFields() { }
}