import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogCommunicator } from 'src/app/components/dialogs/dialog-communicator';
import { DialogData } from 'src/app/entities/dialogData';
import { Entity } from 'src/app/entities/entity';
import { DivisionService } from 'src/app/services/division.service';
import { EntityService } from 'src/app/services/entity.service';
import { LocationService } from 'src/app/services/region.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-entity-dialog',
  templateUrl: './entity-dialog.component.html',
  styleUrls: ['./entity-dialog.component.css']
})
export class EntityDialogComponent extends DialogCommunicator implements OnInit {
  entity: any;
  cities = []
  divisions = []
  constructor(
    public dialogRef: MatDialogRef<EntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    private entityService: EntityService,
    public locationService: LocationService, public divisionService: DivisionService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.locationService.getList('dictionary/city').subscribe(cities => {
      this.divisionService.getList('dictionary/division').subscribe(divisions => {
        this.cities = cities.data
        this.divisions = divisions.data

        if (data) {
          this.entityService.getResource('taxpayer', data['id']).subscribe(taxp => {
            this.entity = taxp.data
          })
        }
      })
    })
  }
  ngOnInit() {

  }
  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.id === value.id;
    return false;
  }

  isReadonly() {
    return this.entity.id && ['registration', 'validation'].includes(this.entity.status)
  }
  //TODO
  downloadFile(key) { }
  //TODO
  approve() { }
}
