import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { SectorService } from 'src/app/services/sector.service';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { SectorDialogComponent } from './dialog/sector-dialog/sector-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'atom-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.css']
})
export class SectorsComponent implements OnInit, OnDestroy {
  subscriptionUpdateSector: any;
  subscriptionDeleteSector: any;
  tabIndex = 0;
  displayedColumns: string[] = ['name', 'branches', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private sectorService: SectorService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateSector = this.sharedService.updateSectorEmmitter.subscribe(val => {
      if (val) {
        this.sectorService.updateResource('dictionary/sector', val).subscribe(res => {
          this.loadsectorTable();
        })
      }
    })
  
    this.subscriptionDeleteSector = this.sharedService.deleteSectorEmmitter.subscribe(val => {
      if (val) {
        this.sectorService.deleteResource('dictionary/sector', val).subscribe(res => {
          this.loadsectorTable();
        })
      }
    })
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.sectorService)
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/sector', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadsectorTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadsectorTable())
      )
      .subscribe();

  }

  loadsectorTable() {
    this.dataSource.loadData('dictionary/sector',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openSectorDialog(sector): void {
    const dialogRef = this.dialog.open(SectorDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: sector
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(sector): void {
    sector['class'] = 'SECTOR';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:sector, text: 'DIALOG.DELETE_CURRENT_SECTOR', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateSector) this.subscriptionUpdateSector.unsubscribe();
    if (this.subscriptionDeleteSector) this.subscriptionDeleteSector.unsubscribe();
  }

}
