import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Sector } from 'src/app/entities/sector';
import { DialogCommunicator } from '../../../../dialogs/dialog-communicator';
import { Branch } from 'src/app/entities/branch';
import { BranchesService } from 'src/app/services/branches.service';

@Component({
  selector: 'app-sector-dialog',
  templateUrl: './sector-dialog.component.html',
  styleUrls: ['./sector-dialog.component.css']
})
export class SectorDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  sector: any;
  branches  = []
  selectBranches: Array<Branch>
 
  constructor(
    public dialogRef: MatDialogRef<SectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public branchService: BranchesService, public sharedService: SharedService,public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.sector = new Sector()
    if (data){
      this.sector = JSON.parse(JSON.stringify(data));
      this.sector['class'] = 'SECTOR'
    }
    this.setEntity(this.sector);
    this.setConfirmationDialog(this.confirmationDialog)
    this.branchService.getList('dictionary/branch').subscribe(branches => {
      this.branches = branches.data.data
      this.selectBranches = this.branches
    })
  }
  selectChange = (event: any, field) => {
    switch (field) {
      case 'BRANCH':
        this.sector.branch = event.data
        break;
      default:
        break;
    }
  };
  ngOnInit() { }
}
