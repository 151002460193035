import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { PostalCodeService } from 'src/app/services/postalCode.service';
import { PostalCodeDialogComponent } from './dialog/postal-code-dialog/postal-code-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
 

@Component({
  selector: 'atom-postal-code',
  templateUrl: './postal-code.component.html',
  styleUrls: ['./postal-code.component.css']
})
export class PostalCodeComponent implements OnInit, OnDestroy {
  subscriptionUpdatePostalCode: any;
  subscriptionDeletePostalCode: any;

  displayedColumns: string[] = ['code', 'region', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private postalCodeService: PostalCodeService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdatePostalCode = this.sharedService.updatePostalCodeEmmitter.subscribe(val => {
      if (val) {
        this.postalCodeService.updateResource('dictionary/postalcode', val).subscribe(res => {
          this.loadPostalCodeTable();
        })
      }
    })
    this.subscriptionDeletePostalCode = this.sharedService.deletePostalCodeEmmitter.subscribe(val => {
      if (val) {
        this.postalCodeService.deleteResource('dictionary/postalcode', val).subscribe(res => {
          this.loadPostalCodeTable();
        })
      }
    })
 
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.postalCodeService)
    // this.dataSource.loadVisits( '', 'asc', 'name', 0, this.paginator.pageSize);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/postalcode', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadPostalCodeTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadPostalCodeTable())
      )
      .subscribe();

  }

  loadPostalCodeTable() {
    this.dataSource.loadData('dictionary/postalcode',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openPostalCodeDialog(postalCode): void {
    const dialogRef = this.dialog.open(PostalCodeDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: postalCode
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(postalCode): void {
    postalCode['class'] = 'POSTALCODE';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:postalCode, text: 'DIALOG.DELETE_CURRENT_POSTAL_CODE', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdatePostalCode) this.subscriptionUpdatePostalCode.unsubscribe();
    if (this.subscriptionDeletePostalCode) this.subscriptionDeletePostalCode.unsubscribe();
  }

}
