 
import { District } from './district';
import { Entity } from './entity';
import { Municipality } from './municipality';

export class City extends Entity {
    public id: number;
    public name: string;
    public municipality: Municipality[];
    public district: District[];

  }  