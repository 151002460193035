import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { TaxRegime } from 'src/app/entities/taxRegime';
import { DialogCommunicator } from 'src/app/components/dialogs/dialog-communicator';

@Component({
  selector: 'app-tax-regime-dialog',
  templateUrl: './tax-regime-dialog.component.html',
  styleUrls: ['./tax-regime-dialog.component.css']
})
export class TaxRegimeDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  taxRegime: any;

  constructor(
    public dialogRef: MatDialogRef<TaxRegimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.taxRegime = new TaxRegime()
    if (data) {
      this.taxRegime = JSON.parse(JSON.stringify(data));
      this.taxRegime['class'] = 'TAXREGIME'
    }
    this.setEntity(this.taxRegime);
    this.setConfirmationDialog(this.confirmationDialog)
  }

  ngOnInit() { }
}
