import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Division } from 'src/app/entities/division';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SectorService } from 'src/app/services/sector.service';
import { PostalCodeService } from 'src/app/services/postalCode.service';
import { PostalCode } from 'src/app/entities/postalCode';
import { Sector } from 'src/app/entities/sector';
import { DivisionService } from 'src/app/services/division.service';

@Component({
  selector: 'atom-division-dialog',
  templateUrl: './division-dialog.component.html',
  styleUrls: ['./division-dialog.component.css']
})
export class DivisionDialogComponent extends DialogCommunicator implements OnInit {
  @ViewChild('postalCodeListSelect', { static: false }) public postalCodeListSelect: NgSelectComponent;
  text: string;
  emitter: EventEmitter;
  division: any;
  postalCodes = []
  sectors = []
  divisions: Array<Division>
  constructor(
    public dialogRef: MatDialogRef<DivisionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    private sectorService: SectorService,
    private divisionService: DivisionService,
    private postalCodeService: PostalCodeService,
    public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.division = new Division()
    if (data) {
      this.division = JSON.parse(JSON.stringify(data));
      this.division['class'] = 'DIVISION'
    }
    this.setEntity(this.division);
    this.setConfirmationDialog(this.confirmationDialog)
    this.postalCodeService.getList('dictionary/postalcode').subscribe(postalCodes => {
      this.postalCodes = postalCodes.data.data
      if (this.division.postalCodes) {
        this.division.postalCodes.forEach(divPostalCode => {

          var existingPostCode = this.postalCodes.find(elem => elem.id === divPostalCode.id)
          this.postalCodes.splice(this.postalCodes.indexOf(existingPostCode), 1, divPostalCode)

          // this.postalCodes.unshift(divPostalCode)
        });
      }
      this.selectPostalCodes = this.postalCodes
    })
    this.sectorService.getList('dictionary/sector').subscribe(sectors => {
      this.sectors = sectors.data.data
      this.selectSectors = this.sectors
      if (this.division && this.division.sectors) {
        this.division.sectors.forEach(divSector => {
          var existingSector = this.sectors.find(elem => elem.id === divSector.id)
          this.sectors.splice(this.sectors.indexOf(existingSector), 1, divSector)
        });
      }
    })
    this.divisionService.getList('dictionary/division').subscribe(divisions => {
      this.divisions = divisions.data.data
      // this.divisions = this.divisions
    })
  }

  selectPostalCodes: Array<PostalCode>
  selectSectors: Array<Sector>
  selectChange = (event: any, field) => {
    switch (field) {
      case 'POSTALCODES':
        this.division.postalCodes = event.data
        break;
      case 'SECTORS':
        this.division.sectors = event.data
        break;
        case 'DIVISION':
        this.division.parentDivision = event.data.length===0?[]:[{ id: event.data[0].id, name: event.data[0].name, key: event.data[0].key}]
        break;
      default:
        break;
    }
  };
  ngOnInit() { }
}