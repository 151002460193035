import { Division } from './division';
import { Entity } from './entity';
import { TaxRegime } from './taxRegime';

export class ActivityDetail extends Entity {
  public id: number;
  public key: string;
  public name: string;
  public profession: boolean;
  public taxRegime: TaxRegime[];
  public division: Division[];
  public cityOffice = {
    structure: [{
      name: 'city', type: 'multiselect', multiselect: true, selectedData: [], key: "name"
    }, {
      name: 'division', type: 'multiselect', multiselect: false, selectedData: [], key: "key"
    }],
    data: []
  }
}  