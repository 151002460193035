import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/sharedService';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/entities/role';
import { MenuCategories } from './menuItems';

@Component({
  selector: 'atom-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {

  title: string = "ATOM"
  lang: string;
  username: string;
  userrole: Role;
  initials: string;
  menuItems: string[][];

  constructor(public auth: AuthService, public sharedService: SharedService, public translate: TranslateService, public router: Router) {
    const token = auth.getToken();
    if (token && token !== 'undefined') {
      const decodedToken = auth.decodeToken(token);
      this.lang = localStorage.getItem("default_lan") ? localStorage.getItem("default_lan") : 'en'
      this.username = decodedToken.sub;
      this.initials = decodedToken.sub.substring(0,1).toUpperCase();
      this.userrole = Role[decodedToken.access.toUpperCase()+""]
      this.menuItems = MenuCategories.get(Role[this.userrole])
    }
  }

  setLang(lang) {
    this.lang = lang
    //this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.sharedService.setLan(lang)
    

  }

  logout() {
    this.username = undefined
    this.auth.logout();
    this.router.navigate(['login']);
  }
  ngOnInit() {
  }

}
