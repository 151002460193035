import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Activity } from 'src/app/entities/activity';
import { DialogCommunicator } from '../../../../dialogs/dialog-communicator';
import { ActivityDetailsService as ActivityService } from 'src/app/services/activityDetails.service';
import { Branch } from 'src/app/entities/branch';

@Component({
  selector: 'app-branches-dialog',
  templateUrl: './branches-dialog.component.html',
  styleUrls: ['./branches-dialog.component.css']
})
export class BranchesDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  branch: any;
  activities  = []
  selectActivities: Array<Activity>
 
  constructor(
    public dialogRef: MatDialogRef<BranchesDialogComponent>, public activityService: ActivityService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.branch = new Branch()
    if (data){
      this.branch = JSON.parse(JSON.stringify(data));
      this.branch['class'] = 'BRANCH'
    }
    this.setEntity(this.branch);
    this.setConfirmationDialog(this.confirmationDialog)
    this.activityService.getList('dictionary/activity').subscribe(activities => {
      this.activities = activities.data.data
      this.selectActivities = this.activities
    })
  }
  selectChange = (event: any, field) => {
    switch (field) {
      case 'ACTIVITY':
        this.branch.activity = event.data
        break;
      default:
        break;
    }
  };
  ngOnInit() { }
}
