import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Application } from 'src/app/entities/application';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { RepresentativeService } from 'src/app/services/representative.service';
import { LocationService } from 'src/app/services/region.service';

@Component({
  selector: 'atom-representative-dialog',
  templateUrl: './representative-dialog.component.html',
  styleUrls: ['./representative-dialog.component.css']
})
export class RepresentativeDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  representative: any;
  corporate: boolean;
  cities = []
  typeContribuables = [{ name: 'Personne Physique' }, { name: 'Société' }]
  sex = [{ name: 'M' }, { name: 'F' }]
  etatCivil = [{ name: 'etatCivil1' }, { name: 'etatCivil2' }]
  paysNationalite = [{ name: 'Cameroun' }, { name: 'Ghana' }]
  paysnaissance = [{ name: 'Cameroun' }, { name: 'Ghana' }]
  activity = [{ name: 'act1' }, { name: 'act2' }]
  sectordgi = [{ name: 'sec1' }, { name: 'sec2' }]
  classINS = [{ name: 'cls1' }, { name: 'cls2' }]
  regimeFiscal = [{ name: 'reg1' }, { name: 'reg2' }]
  unitegestion = [{ name: 'un1' }, { name: 'un2' }]
  ville = [{ name: 'vil1' }, { name: 'vil2' }]
  etat = [{ name: 'etat1' }, { name: 'etat2' }]
  online = [{ name: 'onlin1' }, { name: 'onlin2' }]

  constructor(
    public dialogRef: MatDialogRef<RepresentativeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public locationService: LocationService, public representativeService: RepresentativeService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.representative = new Application()

    this.locationService.getList('dictionary/city').subscribe(cities => {
        this.cities = cities.data
        if (data) {
          var appData = JSON.parse(JSON.stringify(data));
          if (appData['representative']) {
            this.representative = appData['representative'];
            this.representative['class'] = 'REPRESENTATIVE'
          } else {
            this.representative.type = appData.type
          }

        }
        this.setEntity(this.representative);
        this.setConfirmationDialog(this.confirmationDialog)
    })

  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.id === value.id;
    return false;
  }

  // approve() {
  //   this.representativeService.changeRepresentativeStatus('representative/' + this.representative.id + '/approve', { comment: this.representative.comment }).subscribe(result => {
   
  //   })
  // }

  isReadonly() {
    return this.representative.id && this.representative.status === 'validation'
  }

  ngOnInit() { }
}