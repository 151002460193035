import { UserStatus } from "./states/userStatus";
import { Role } from "./role";

const UserStatusName = new Map<number, string>([
  [UserStatus.ACTIVE, "ACTIVE"],
  [UserStatus.SUSPENDED, "SUSPENDED"],
]);

const RoleName = new Map<number, string>([
  [Role.ADMIN, "ADMIN"],
  [Role.EDITOR, "EDITOR"],
  [Role.READONLY, "READONLY"],
]);

var replaceValues = (obj1: any, obj2: any) => {
  for (var key in obj1) {
    delete obj1[key];
  }
  for (var key in obj2) {
    obj1[key] = obj2[key];
  }
};

const preExistingCategoryFields = [
  {
    key: "region",
    name: {
      en: "Region",
      fr: "Region",
    },
    type: "region dropdown",
    required: true,
    changeable: false,
    duplicate: false,
    nonFinalized: false,
    columnStyle: false,
    preExisting: true
  },
  {
    key: "department",
    name: {
      en: "Department",
      fr: "Department",
    },
    type: "department dropdown",
    required: true,
    changeable: false,
    duplicate: false,
    nonFinalized: false,
    columnStyle: false,
    preExisting: true
  },
];

export { UserStatusName, RoleName, replaceValues, preExistingCategoryFields };
