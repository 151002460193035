import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { SectorService } from 'src/app/services/sector.service';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { SectorDialogComponent } from './sectors/dialog/sector-dialog/sector-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'atom-sector',
  templateUrl: './sector.component.html',
  styleUrls: ['./sector.component.css']
})
export class SectorComponent implements OnInit {
  tabIndex = 0;

  constructor(private sectorService: SectorService, public dialog: MatDialog, public sharedService: SharedService) {
   
  }

  ngOnInit() {
    
  }
 
}
