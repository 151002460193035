import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { MultiselectAutocompleteComponent } from '../../multiselect-autocomplete/multiselect-autocomplete.component';

@Component({
  selector: 'atom-addable',
  templateUrl: './addable.component.html',
  styleUrls: ['./addable.component.css']
})
export class AddableComponent implements OnInit {
  @ViewChildren('multiSelect') multiSelects: QueryList<MultiselectAutocompleteComponent>
  @Input() addableDataAndStructure: {}
  dataArr: any[]
  componentsData = {}
  @Output() addData: EventEmitter<any> = new EventEmitter();
  constructor(public baseService: BaseService) { }

  ngOnInit() {
    this.dataArr = new Array<any>(this.addableDataAndStructure['structure'].length);
    this.addableDataAndStructure['structure'].forEach((elem, index) => {
      this.addableDataAndStructure['data'].forEach(elemData => {
        for (var key in elem) {
          if (key !== 'selectedData')
            elemData[index][key] = elem[key]
        }
      })
      this.baseService.getList('dictionary/' + elem.name).subscribe(data => {
        this.componentsData[elem.name] = data.data.data
      })
    })
  }

  selectChange = (event: any, field, dataIndex, componentIndex) => {
    var str = this.addableDataAndStructure['structure'].find(elem => elem.name === field)
    if (dataIndex !== null && componentIndex !== null) {
      this.addableDataAndStructure['data'][dataIndex][componentIndex]['selectedData'] = event.data.map(obj => { return { name: obj.name, key: obj.key, id: obj.id } })
    }
    else{
      var dataStr = {}
      for (var key in str) {
        dataStr[key] = str[key]
      }
      if (dataStr['selectedData']) {
        dataStr['selectedData'] = event.data.map(obj => { return { name: obj.name, key: obj.key, id: obj.id } })
      }
      this.dataArr.splice(this.addableDataAndStructure['structure'].indexOf(str), 1, (dataStr['selectedData'].length > 0 || (dataIndex !== null && componentIndex !== null)) ? dataStr : undefined)
    }
  }

  deleteField(index) {
    this.addableDataAndStructure['data'].splice(index, 1)
  }


  emptyFields() {
    return this.dataArr.includes(undefined)
  }

  addField() {
    this.addData.emit(this.dataArr)
    this.dataArr = new Array<any>(this.addableDataAndStructure['structure'].length);
    this.multiSelects.forEach(elem => elem.resetAll())
  }

}
