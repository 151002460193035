import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SharedService } from './sharedService';
import { catchError, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends BaseService{
  
  changeApplicationStatus(resourceUrl: string, resource: any): Observable<any> {
      return this.http.put<any>(this.sharedService.apiUrl(resourceUrl), resource, super.httpOptions).pipe(
        tap((resource: any) => this.log(`statusChange ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('statusChange'))
      );
  }

  downloadFile(name: any): Observable<Blob> {
    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json', 
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('application/file')+name, httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download file'))
    )
    return process$;

  }
  getLocations(resourceUrl:string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl)).pipe(
      map(res => {
        return res;
      })
    );
  }
}

 
