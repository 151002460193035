const AdminMenu =  [
    ["/applications", 'ADMIN-TOP-MENU.APPLICATIONS'],
    ["/entities", 'ADMIN-TOP-MENU.ENTITIES'], 
    ["/settings", 'ADMIN-TOP-MENU.SETTINGS'],
];

const EditorMenu =  [
    ["/applications", 'ADMIN-TOP-MENU.APPLICATIONS'],
    ["/entities", 'ADMIN-TOP-MENU.ENTITIES'],
 
];
const TaxpayerDBMenu =  [
    ["/entities", 'ADMIN-TOP-MENU.ENTITIES'],
];

const CompanyMenu = [
    ["/company_menu_item_1", 'COMPANY-TOP-MENU.COMPANY_MENU_ITEM_1']
];

const CommonMenu = [
    ["/settings", 'COMMON-TOP-MENU.SETTINGS'],
    ["/help", 'COMMON-TOP-MENU.HELP']     
];

const MenuCategories = new Map<string, string[][]>([
    ["ADMIN", AdminMenu],
    ["VIEWER", AdminMenu],
    ["EDITOR", EditorMenu],
    ["TAXPAYER_DB", TaxpayerDBMenu],
    ["COMPANY", CompanyMenu],
    ["COMMON", CommonMenu]
]);

export { MenuCategories }