import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { DivisionService } from 'src/app/services/division.service';
import { Department } from 'src/app/entities/department';
import { City } from 'src/app/entities/city';
import { LocationService } from 'src/app/services/region.service';
import { MultiselectAutocompleteComponent } from 'src/app/components/multiselect-autocomplete/multiselect-autocomplete.component';

@Component({
  selector: 'atom-department-dialog',
  templateUrl: './department-dialog.component.html',
  styleUrls: ['./department-dialog.component.css']
})
export class DepartmentDialogComponent extends DialogCommunicator implements OnInit {
  @ViewChild('municipalitySelect', { static: false }) munSel: MultiselectAutocompleteComponent;
  @ViewChild('citySelect', { static: false }) citSel: MultiselectAutocompleteComponent;
  text: string;
  emitter: EventEmitter;
  department: any;
  cities  = []
  selectCities: Array<City>
  municipalities = []
  selectMunicipalities: Array<City>
  constructor(
    public dialogRef: MatDialogRef<DepartmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public locationService: LocationService,
    public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.department = new Department()
    if (data){
      this.department = JSON.parse(JSON.stringify(data));
      this.department['class'] = 'DEPARTMENT'
    }
    this.setEntity(this.department);
    this.setConfirmationDialog(this.confirmationDialog)

    this.locationService.getList('dictionary/municipality').subscribe(municipalities => {
      this.municipalities = municipalities.data.data
      this.selectMunicipalities = this.municipalities
    })
    this.locationService.getList('dictionary/city').subscribe(cities => {
      this.cities = cities.data.data
      this.selectCities = this.cities
    })

  }

  selectChange = (event: any, field) => {
    switch (field) {
      case 'MUNICIPALITY':
        this.department.municipality = event.data
        this.department.city = null
        this.citSel.resetAll()
        break;
      case 'CITY':
        this.department.city = event.data
        this.department.municipality = null
        this.munSel.resetAll()
        break;
      default:
        break;
    }
  };

  ngOnInit() { }
}