import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { CategoryService } from 'src/app/services/category.service';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { CategoryDialogComponent } from './dialog/category-dialog/category-dialog.component';

@Component({
  selector: 'atom-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit, OnDestroy {
  subscriptionUpdateCategory: any;
  subscriptionDeleteCategory: any;

  displayedColumns: string[] = ['name', 'tinCode', 'taxPayerType', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private categoryService: CategoryService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateCategory = this.sharedService.updateCategoryEmmitter.subscribe(val => {
      if (val) {
        this.categoryService.updateResource('dictionary/category', val).subscribe(res => {
          this.loadCategoryTable();
        })
      }
    })
  
    this.subscriptionDeleteCategory = this.sharedService.deleteCategoryEmmitter.subscribe(val => {
      if (val) {
        this.categoryService.deleteResource('dictionary/category', val).subscribe(res => {
          this.loadCategoryTable();
        })
      }
    })
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.categoryService)
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.loadData('dictionary/category', '', 'asc', 'name', 0, this.paginator.pageSize,{});
    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadCategoryTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadCategoryTable())
      )
      .subscribe();

  }

  loadCategoryTable() {
    this.dataSource.loadData('dictionary/category',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openCategoryDialog(category): void {
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      width: '700px', panelClass: 'custom-dialog-container',
      data: category
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(category): void {
    category['class'] = 'CATEGORY';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:category, text: 'DIALOG.DELETE_CURRENT_CATEGORY', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateCategory) this.subscriptionUpdateCategory.unsubscribe();
    if (this.subscriptionDeleteCategory) this.subscriptionDeleteCategory.unsubscribe();
  }

}
