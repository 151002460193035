import { Injectable, Output, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";
import { City } from '../entities/city';
import { Division } from '../entities/division';
import { User } from '../entities/user';
import { Application } from "../entities/application";
import { Sector } from "../entities/sector";
import { Category } from "../entities/category";
import { Region } from "../entities/region";
import { PostalCode } from "../entities/postalCode";
import { Municipality } from "../entities/municipality";
import { Department } from "../entities/department";
import { District } from "../entities/district";
import { Branch } from "../entities/branch";
import { Activity } from "../entities/activity";
import { ActivityDetail } from "../entities/activityDetail";
import { TaxRegime } from "../entities/taxRegime";

@Injectable()
export class SharedService {
  private lan$ = new Subject<any>();
  private serverPath: string;
  taxPayerTypes = [{ key: "COMPANY", value: "Company" }, { key: "INDIVIDUAL", value: "Individual" }]
  taxPayerCodes = ["IW","CO","IN"]
  constructor() {

  }

  apiUrl(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI + '/' + parameters;
  }

  apiUrlLogin(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return Observable.throw(error.error || 'Server error');
    };
  }


  log(message: string) {
  }

  getLan$(): Observable<any> {
    return this.lan$.asObservable();
  }

  getLan() {
    return localStorage.getItem('default_lan');
  }

  setLan(lan) {
    localStorage.setItem('default_lan', lan);
    this.lan$.next(lan);
  }
  getAccessToken() {
    return environment.accessToken;
  }


  @Output() deactivateUserEmmitter: EventEmitter<User> = new EventEmitter();
  deactivateEntity(obj) {

    if (obj instanceof User || obj['class'] === 'USER') {
      this.deactivateUserEmmitter.emit(obj)
    }
  }

  @Output() activateUserEmmitter: EventEmitter<User> = new EventEmitter();
  activateEntity(obj) {

    if (obj instanceof City || obj['class'] === 'USER') {
      this.activateUserEmmitter.emit(obj)
    }
  }

  @Output() updateUserEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateApplicationEmmitter: EventEmitter<Application> = new EventEmitter();
  @Output() updateCityEmmitter: EventEmitter<City> = new EventEmitter();
  @Output() updateRegionEmmitter: EventEmitter<Region> = new EventEmitter();
  @Output() updatePostalCodeEmmitter: EventEmitter<PostalCode> = new EventEmitter();
  @Output() updateDivisionEmmitter: EventEmitter<Division> = new EventEmitter();
  @Output() updateSectorEmmitter: EventEmitter<Sector> = new EventEmitter();
  @Output() updateCategoryEmmitter: EventEmitter<City> = new EventEmitter();
  @Output() approveRepresentativeEmmitter: EventEmitter<any> = new EventEmitter();
  @Output() updateMunicipalityEmmitter: EventEmitter<any> = new EventEmitter();
  @Output() updateDepartmentEmmitter: EventEmitter<any> = new EventEmitter();
  @Output() updateDistrictEmmitter: EventEmitter<any> = new EventEmitter();
  @Output() updateActivityDetailEmmitter: EventEmitter<ActivityDetail> = new EventEmitter();
  @Output() updateActivityEmmitter: EventEmitter<Activity> = new EventEmitter();
  @Output() updateBranchEmmitter: EventEmitter<Branch> = new EventEmitter();
  @Output() updateTaxRegimeEmmitter: EventEmitter<Branch> = new EventEmitter();

  @Output() deleteUserEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() deleteApplicationEmmitter: EventEmitter<Application> = new EventEmitter();
  @Output() deleteCityEmmitter: EventEmitter<City> = new EventEmitter();
  @Output() deleteRegionEmmitter: EventEmitter<Region> = new EventEmitter();
  @Output() deletePostalCodeEmmitter: EventEmitter<PostalCode> = new EventEmitter();
  @Output() deleteDivisionEmmitter: EventEmitter<Division> = new EventEmitter();
  @Output() deleteSectorEmmitter: EventEmitter<Sector> = new EventEmitter();
  @Output() deleteActivityDetailEmmitter: EventEmitter<ActivityDetail> = new EventEmitter();
  @Output() deleteActivityEmmitter: EventEmitter<Activity> = new EventEmitter();
  @Output() deleteBranchEmmitter: EventEmitter<Branch> = new EventEmitter();
  @Output() deleteCategoryEmmitter: EventEmitter<Category> = new EventEmitter();
  @Output() deleteMunicipalityEmmitter: EventEmitter<Category> = new EventEmitter();
  @Output() deleteDepartmentEmmitter: EventEmitter<Category> = new EventEmitter();
  @Output() deleteDistrictEmmitter: EventEmitter<Category> = new EventEmitter();
  @Output() deleteTaxRegimeEmmitter: EventEmitter<Category> = new EventEmitter();

  updateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.updateUserEmmitter.emit(obj)
    }
    if (obj instanceof City || obj['class'] === 'CITY') {
      this.updateCityEmmitter.emit(obj)
    }
    if (obj instanceof Region || obj['class'] === 'REGION') {
      this.updateRegionEmmitter.emit(obj)
    }
    if (obj instanceof PostalCode || obj['class'] === 'POSTALCODE') {
      this.updatePostalCodeEmmitter.emit(obj)
    }
    if (obj instanceof Division || obj['class'] === 'DIVISION') {
      this.updateDivisionEmmitter.emit(obj)
    }
    if (obj instanceof Sector || obj['class'] === 'SECTOR') {
      this.updateSectorEmmitter.emit(obj)
    }
    if (obj instanceof Application || obj['class'] === 'APPLICATION') {
      this.updateApplicationEmmitter.emit(obj)
    }
    if (obj instanceof Category || obj['class'] === 'CATEGORY') {
      this.updateCategoryEmmitter.emit(obj)
    }
    if (obj instanceof Municipality || obj['class'] === 'MUNICIPALITY') {
      this.updateMunicipalityEmmitter.emit(obj)
    }
    if (obj instanceof Department || obj['class'] === 'DEPARTMENT') {
      this.updateDepartmentEmmitter.emit(obj)
    }
    if (obj instanceof District || obj['class'] === 'DISTRICT') {
      this.updateDistrictEmmitter.emit(obj)
    }
    if (obj instanceof ActivityDetail || obj['class'] === 'ACTIVITYDETAIL') {
      this.updateActivityDetailEmmitter.emit(obj)
    }
    if (obj instanceof Activity || obj['class'] === 'ACTIVITY') {
      this.updateActivityEmmitter.emit(obj)
    }
    if (obj instanceof Branch || obj['class'] === 'BRANCH') {
      this.updateBranchEmmitter.emit(obj)
    }
    if (obj instanceof TaxRegime || obj['class'] === 'TAXREGIME') {
      this.updateTaxRegimeEmmitter.emit(obj)
    }
  }

  deleteEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.deleteUserEmmitter.emit(obj)
    }
    if (obj instanceof City || obj['class'] === 'CITY') {
      this.deleteCityEmmitter.emit(obj)
    }
    if (obj instanceof Region || obj['class'] === 'REGION') {
      this.deleteRegionEmmitter.emit(obj)
    }
    if (obj instanceof PostalCode || obj['class'] === 'POSTALCODE') {
      this.deletePostalCodeEmmitter.emit(obj)
    }
    if (obj instanceof Division || obj['class'] === 'DIVISION') {
      this.deleteDivisionEmmitter.emit(obj)
    }
    if (obj instanceof Sector || obj['class'] === 'SECTOR') {
      this.deleteSectorEmmitter.emit(obj)
    }
    if (obj instanceof Application || obj['class'] === 'APPLICATION') {
      this.deleteApplicationEmmitter.emit(obj)
    }
    if (obj instanceof Category || obj['class'] === 'CATEGORY') {
      this.deleteCategoryEmmitter.emit(obj)
    }
    if (obj instanceof Municipality || obj['class'] === 'MUNICIPALITY') {
      this.deleteMunicipalityEmmitter.emit(obj)
    }
    if (obj instanceof Department || obj['class'] === 'DEPARTMENT') {
      this.deleteDepartmentEmmitter.emit(obj)
    }
    if (obj instanceof District || obj['class'] === 'DISTRICT') {
      this.deleteDistrictEmmitter.emit(obj)
    }
    if (obj instanceof ActivityDetail || obj['class'] === 'ACTIVITYDETAIL') {
      this.deleteActivityDetailEmmitter.emit(obj)
    }
    if (obj instanceof Activity || obj['class'] === 'ACTIVITY') {
      this.deleteActivityEmmitter.emit(obj)
    }
    if (obj instanceof Branch || obj['class'] === 'BRANCH') {
      this.deleteBranchEmmitter.emit(obj)
    }
    if (obj instanceof TaxRegime || obj['class'] === 'TAXREGIME') {
      this.deleteTaxRegimeEmmitter.emit(obj)
    }
  }

  approveRepresentative() {
    this.approveRepresentativeEmmitter.emit()
  }






}
