import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { ActivitiesDialogComponent } from './dialog/activities/activities-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ActivitiesService } from 'src/app/services/activities.service';

@Component({
  selector: 'atom-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  subscriptionUpdateActivity: any;
  subscriptionDeleteActivity: any;
  tabIndex = 0;
  displayedColumns: string[] = ['name', 'activityDetails', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private activitiesService: ActivitiesService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateActivity = this.sharedService.updateActivityEmmitter.subscribe(val => {
      if (val) {
        this.activitiesService.updateResource('dictionary/activity', val).subscribe(res => {
          this.loacActivityTable();
        })
      }
    })
  
    this.subscriptionDeleteActivity = this.sharedService.deleteActivityEmmitter.subscribe(val => {
      if (val) {
        this.activitiesService.deleteResource('dictionary/activity', val).subscribe(res => {
          this.loacActivityTable();
        })
      }
    })
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.activitiesService)
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/activity', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loacActivityTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loacActivityTable())
      )
      .subscribe();

  }

  loacActivityTable() {
    this.dataSource.loadData('dictionary/activity',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openActivityDialog(activity): void {
    const dialogRef = this.dialog.open(ActivitiesDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: activity
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(activity): void {
    activity['class'] = 'ACTIVITY';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:activity, text: 'DIALOG.DELETE_CURRENT_ACTIVITY', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateActivity) this.subscriptionUpdateActivity.unsubscribe();
    if (this.subscriptionDeleteActivity) this.subscriptionDeleteActivity.unsubscribe();
  }

}
