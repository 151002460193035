import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatPaginator, MatSort } from "@angular/material";
import { SharedService } from "src/app/services/sharedService";
import { AtomDataSource } from "src/app/services/atomTable.datasource";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ApplicationService } from "src/app/services/application.service";
import { ApplicationDialogComponent } from "./dialog/application-dialog/application-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "atom-application",
  templateUrl: "./application.component.html",
  styleUrls: ["./application.component.css"],
})
export class ApplicationComponent implements OnInit {
  subscriptionUpdateApplication: any;
  displayedColumns: string[] = [
    "name",
    "type",
    "tin",
    "address",
    "city",
    "turnover",
    "division",
    "date",
    "status",
  ];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild("input_search", { static: false }) input: ElementRef;
  currentFilter = "All Applications";
  displayedFilters: string[] = ["", "", "", ""];
  statusValue;
  reasonType = "DUPLICATE";
  objectFilter = {};
  constructor(
    public translateService: TranslateService,
    public dialog: MatDialog,
    public sharedService: SharedService,
    private applicationService: ApplicationService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.translateService
      .get("APPLICATIONS.ALL-APPLICATIONS")
      .subscribe((translated: string) => {
        this.setFilter()
      });

    this.subscriptionUpdateApplication =
      this.sharedService.updateApplicationEmmitter.subscribe((val) => {
        if (val) {
          this.applicationService
            .updateResource("application", val)
            .subscribe((res) => {
              this.loadApplicationTable();
            });
        }
      });

      this.sharedService.getLan$().subscribe(lang => {
        this.setFilter()
      })
  }

    setFilter(){
    this.displayedFilters[0] = this.translateService.instant(
      "APPLICATIONS.DUPLICATES"
    );
    this.displayedFilters[1] = this.translateService.instant(
      "APPLICATIONS.TO-APPROVE"
    );
    this.displayedFilters[2] = this.translateService.instant(
      "APPLICATIONS.RECENT"
    );
    this.displayedFilters[3] = this.translateService.instant(
      "APPLICATIONS.ALL-APPLICATIONS"
    );
    this.currentFilter = this.translateService.instant(
      "APPLICATIONS.DUPLICATES"
    );
  }

  handleSelection(event) {
    var statusFilter = "status";
    this.statusValue = null;
    this.reasonType = null;
    if (event.value === this.displayedFilters[0]) {
      this.statusValue = "reject";
      this.reasonType = "DUPLICATE";
      this.objectFilter = { status : "reject", reasonType : "DUPLICATE"}
    } else if (event.value === this.displayedFilters[1]) {
      this.statusValue = "reject";
      this.reasonType = "NON_FINALIZED";
      this.objectFilter = { status : "reject", reasonType : "NON_FINALIZED"}
    } else if (event.value === this.displayedFilters[2]) {
      this.objectFilter = { status : "active"}
    } else {
      statusFilter = null;
      this.objectFilter = {}
    }
     
    this.dataSource.loadData(
      "application",
      this.reasonType ? { reasonType: this.reasonType } : null,
      "asc",
      "name",
      0,
      this.paginator.pageSize,
      this.objectFilter
    );
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.applicationService);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let tab = this.route.snapshot.queryParamMap.get("tab");
      if (tab) {
        this.statusValue = tab;
        switch (tab) {
          case "registration":
            this.currentFilter = this.translateService.instant(
              "APPLICATIONS.DUPLICATES"
            );
            this.handleSelection({ value: this.currentFilter });
            break;

          case "validation":
            this.currentFilter = this.translateService.instant(
              "APPLICATIONS.TO-APPROVE"
            );
            this.handleSelection({ value: this.currentFilter });
            break;

          case "recent":
            if (this.reasonType)
              this.objectFilter["reasonType"] = this.reasonType;
            this.currentFilter = this.translateService.instant(
              "APPLICATIONS.RECENT"
            );
            this.dataSource.loadData(
              "application/recent",
              "",
              "asc",
              "name",
              0,
              this.paginator.pageSize,
              this.objectFilter
            );
            break;

          default:
            break;
        }
      } else {
        if (this.reasonType) this.objectFilter["reasonType"] = this.reasonType;
        this.currentFilter = this.translateService.instant(
          "APPLICATIONS.DUPLICATES"
        );
        this.dataSource.loadData(
          "application",
          "",
          "asc",
          "name",
          0,
          this.paginator.pageSize,
          this.objectFilter
        );
      }
    }, 0);

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadApplicationTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadApplicationTable()))
      .subscribe();
  }

  loadApplicationTable() {
    this.dataSource.loadData(
      "application",
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      {}
    );
  }

  openApplicationView(applicationId) {
    let queryParams = {
      backRoute: "applications",
    };
    if (this.statusValue) {
      queryParams["tab"] = this.statusValue;
    }
    this.router.navigate(["/application/" + applicationId], { queryParams });
  }

  openDialog(type, application): void {
    const dialogRef = this.dialog.open(ApplicationDialogComponent, {
      width: "850px",
      panelClass: "custom-dialog-container",
      data: { type, application: application },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateApplication)
      this.subscriptionUpdateApplication.unsubscribe();
  }
}
