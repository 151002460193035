import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { MunicipalityDialogComponent } from './dialog/municipality-dialog/municipality-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { LocationService } from 'src/app/services/region.service';
 

@Component({
  selector: 'atom-municipality',
  templateUrl: './municipality.component.html',
  styleUrls: ['./municipality.component.css']
})
export class MunicipalityComponent implements OnInit, OnDestroy {
  subscriptionUpdateMunicipality: any;
  subscriptionDeleteMunicipality: any;

  displayedColumns: string[] = ['name', 'tinCode', 'division', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private locationService: LocationService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateMunicipality = this.sharedService.updateMunicipalityEmmitter.subscribe(val => {
      if (val) {
        this.locationService.updateResource('dictionary/municipality', val).subscribe(res => {
          this.loadMunicipalityTable();
        })
      }
    })
    this.subscriptionDeleteMunicipality = this.sharedService.deleteMunicipalityEmmitter.subscribe(val => {
      if (val) {
        this.locationService.deleteResource('dictionary/municipality', val).subscribe(res => {
          this.loadMunicipalityTable();
        })
      }
    })
 
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.locationService)
    // this.dataSource.loadVisits( '', 'asc', 'name', 0, this.paginator.pageSize);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/municipality', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadMunicipalityTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadMunicipalityTable())
      )
      .subscribe();

  }

  loadMunicipalityTable() {
    this.dataSource.loadData('dictionary/municipality',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openMunicipalityDialog(municipality): void {
    const dialogRef = this.dialog.open(MunicipalityDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: municipality
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(municipality): void {
    municipality['class'] = 'MUNICIPALITY';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:municipality, text: 'DIALOG.DELETE_CURRENT_MUNICIPALITY', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateMunicipality) this.subscriptionUpdateMunicipality.unsubscribe();
    if (this.subscriptionDeleteMunicipality) this.subscriptionDeleteMunicipality.unsubscribe();
  }

}
