import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { CityDialogComponent } from './dialog/city-dialog/city-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { LocationService } from 'src/app/services/region.service';
 

@Component({
  selector: 'atom-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit, OnDestroy {
  subscriptionUpdateCity: any;
  subscriptionDeleteCity: any;

  displayedColumns: string[] = ['name', 'municipality', 'distirct', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private locationService: LocationService, public dialog: MatDialog, public sharedService: SharedService) {
    this.subscriptionUpdateCity = this.sharedService.updateCityEmmitter.subscribe(val => {
      if (val) {
        this.locationService.updateResource('dictionary/city', val).subscribe(res => {
          this.loadCityTable();
        })
      }
    })
    this.subscriptionDeleteCity = this.sharedService.deleteCityEmmitter.subscribe(val => {
      if (val) {
        this.locationService.deleteResource('dictionary/city', val).subscribe(res => {
          this.loadCityTable();
        })
      }
    })
 
  }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.locationService)
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('dictionary/city', '', 'asc', 'name', 0, this.paginator.pageSize, {});

    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadCityTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadCityTable())
      )
      .subscribe();

  }

  loadCityTable() {
    this.dataSource.loadData('dictionary/city',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

  openCityDialog(city): void {
    const dialogRef = this.dialog.open(CityDialogComponent, {
      width: '550px', panelClass: 'custom-dialog-container',
      data: city
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDeleteDialog(city): void {
    city['class'] = 'CITY';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: {obj:city, text: 'DIALOG.DELETE_CURRENT_CITY', action: 'DELETE' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateCity) this.subscriptionUpdateCity.unsubscribe();
    if (this.subscriptionDeleteCity) this.subscriptionDeleteCity.unsubscribe();
  }

}
