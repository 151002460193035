import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { DialogData } from 'src/app/entities/dialogData';
import { UserStatusName } from 'src/app/entities/util';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entities/user';
import { DivisionService } from 'src/app/services/division.service';
import { UserStatus } from 'src/app/entities/states/userStatus';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'bog-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css']
})
export class UserDialogComponent extends DialogCommunicator implements OnInit, OnDestroy {

  text: string;
  updateUserEmmiter: EventEmitter<any>;
  user: any;
  statusNames = UserStatusName
  userStatus = UserStatus
  divisions = [];
  usernames = [];
  userroles = ['READONLY', 'EDITOR', 'ADMIN'];
  isLoading = false;
  errorMsg: string;
  repeatPassword = "";
  public searchUsername = new FormControl();
  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService, public userService: UserService, public divisionService: DivisionService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.setConfirmationDialog(this.confirmationDialog)
    this.updateUserEmmiter = new EventEmitter();
  }

  ngOnInit() {
    this.user = new User()
    this.user.roles = []
    this.divisionService.getList('dictionary/division').subscribe(result => {
      this.divisions = result.data
      if (this.data) {
        this.userService.getResource('user', this.data['username']).subscribe(val => {
          this.user = val.data
          this.user['class'] = 'USER'
          this.setEntity(this.user);
        }
        )
      } else {
        this.setEntity(this.user);
      }
      this.setConfirmationDialog(this.confirmationDialog)
    })
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.id === value.id;
    return false;
  }
  onChangeRole(chBox, role) {
    if (chBox.checked) {
      if (this.user.roles)
        this.user.roles.push(role)
      else
        this.user.roles = [role]
    } else {
      this.user.roles = this.user.roles.filter(r => r !== role)
    }
  }
  hasRole(role) {
    if (this.user.roles) {
      return this.user.roles.find(elem => elem === role)
    }
    return false
  }

  updateUser(action: any, dialogText: any) {
    if (this.data) {
      this.user.id = this.user.username
      this.onUpdateClick(action, dialogText)
    }
    else {

      //this.updateUserEmmiter.subscribe(val => {
        this.onUpdateClick(action, dialogText)
      //})

    }
  }
  ngOnDestroy() {
    this.updateUserEmmiter.unsubscribe()
  }

}
