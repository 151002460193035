import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AtomDataSource } from 'src/app/services/atomTable.datasource';
import { DivisionService } from 'src/app/services/division.service';

@Component({
  selector: 'app-logging-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['date', 'source', 'user', 'message', 'action'];
  dataSource: AtomDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;
  constructor(private divisionService: DivisionService) { }

  ngOnInit() {
    this.dataSource = new AtomDataSource(this.divisionService)
  }

  ngAfterViewInit() {
    
    setTimeout(() => {
      this.dataSource.loadData('action-log', '', 'asc', 'source', 0, this.paginator.pageSize, {});
    }, 0)

    this.sort.sortChange.subscribe(() => {});

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadLogTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadLogTable())
      )
      .subscribe();
  }

  loadLogTable() {
    this.dataSource.loadData('action-log',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, {});
  }

}
